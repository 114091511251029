import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { auth, db } from "../../../firebase.config";
import { showToast } from "../../../utils/toast";
import { formatDate } from "../../../utils/utils";

const ExportGST = () => {
  const [uid, setuid] = useState("");
  const [name, setname] = useState("");
  const [data, setdata] = useState();
  const [sign, setsign] = useState("");
  const [user, setuser] = useState();
  const navigate = useNavigate();
  const pdfref = useRef(null);

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  useEffect(() => {
    if (useruid == "") {
      navigate("/dashboard");
    }
    setuid(useruid);
  }, [useruid]);

  const handlePrint = () => {
    document.getElementById("printbtn").style.display = "none";
    let printContents = document.getElementById("printableDIV").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    document.getElementById("printbtn").style.display = "block";
  };

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setuser(_tmp);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (uid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        const _tmp = doc.data();
        setdata(_tmp);
        setname(_tmp?.name);
      });
      onSnapshot(doc(db, "documents", useruid), (doc) => {
        const _tmp = doc.data();
        setsign(_tmp?.signImage);
      });
    }
  }, [uid]);

  const ComponentPrint = () => {
    return (
      <div
        ref={printDivRef}
        id="printref"
        style={{ height: "calc(100vh - 145px)", overflow: "auto" }}
        className="mt-3 scroll-hide w-full rounded-lg  bg-white text-black"
      >
        <div className="text-justify overflow-y-auto p-20 pt-10">
          {/* <button id="printbtn" className='rounded-lg bg-blue-800 text-white w-full p-2 my-3' onClick={handlePrint}>Print</button> */}
          <p className="text-end">
            <b>Date: {formatDate("-", data?.nongst?.date)}</b>
          </p>
          <p>
            <b>
              From,
              <br />
              {data?.name || "Your Name"}
            </b>
          </p>
          <br />
          <p>
            <b>To,</b>
            <br />
            <p>
              Omnicom Media Group India Private Limited
              <br />
              GSTIN-{data?.nongst?.gstinNumber}
              <br />
              {data?.nongst?.state}
              <br />
            </p>
          </p>
          <br />
          <p className="border-b-2 border-gray-900">
            <b>Sub:</b> Declaration regarding non-requirement for registration
            under the Central/ State/ Integrated Goods and Services Tax Act,
            2017 (‘Act)
          </p>
          <br />
          <p>
            <b>Dear Sir/Madam,</b>
          </p>
          <br />
          <p>
            Regarding requirement of GST registration and to provide you with
            details of GST registrations obtained by me such as GST registration
            number, GST ARN and the address registered under GST.
          </p>
          <br />
          <p>
            In this connection, I hereby state that since aggregate turnover in
            a financial year is below the prescribed limit, so I am not required
            to get myself registered under the Act due to the applicability of
            Section 22 (1) of the Act.
          </p>
          <br />
          <p>
            I request you to treat this communication as a declaration regarding
            non-requirement to be registered under the Act.
          </p>
          <br />
          <p>
            In case, I obtain GST registration due to change of facts or change
            in the law, I shall immediately inform you about such change.
          </p>
          <br />
          <b>
            Thanks and regards,
            <br />
            {sign ? (
              <img src={sign} alt="Signature" className="signImage my-2 ms-3" />
            ) : (
              <>
                <br />
                <br />
                <br />
              </>
            )}
            {name || "Your Name"}
          </b>
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (useruid) {
      if (
        (data?.forms?.nonapplicability == 1 &&
          data?.queries?.nonapplicability == "") ||
        data?.queries?.nonapplicability == undefined
      ) {
        await updateDoc(doc(db, "users", useruid), {
          "forms.nonapplicability": 2,
        });
        showToast("success", "Non-applicability of GST Validated!");
      } else {
        showToast("warning", "Form is Incomplete!");
      }
    }
  };

  const printDivRef = useRef();

  return (
    <>
      <div>
        <ComponentPrint />
        {user?.usertype != "auditor" ? (
          <>
            {data?.forms?.nonapplicability == 1 && (
              <button
                onClick={(e) => handleSubmit(e)}
                type="submit"
                className="rounded-lg my-3 bg-blue-800 text-white w-full p-2 disabled:bg-blue-900"
                disabled={data?.forms?.nonapplicability >= 2}
              >
                Validate
              </button>
            )}
            {data?.forms?.nonapplicability >= 2 ? (
              <>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button className="rounded-lg bg-blue-800 text-white w-full p-2 my-3">
                        Print
                      </button>
                    );
                  }}
                  content={() => printDivRef.current}
                  documentTitle="Vendor Data Form"
                  pageStyle="print"
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ExportGST;
