const initialState = {
    uid: "",
    allusers: {},
    error_msg: null,
    success_msg: null,
    loading: true,
    isSuccess: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GETALLUSERS:
            return {
                ...state,
                allusers: action.payload,
                loading: false,
            }
        case SETID:
            return {
                ...state,
                uid: action.payload,
                loading: false,
            }
        default:
            return {
                ...state
            }
    }
}

export const GETALLUSERS = "ON_GET_ALL_USER_SUCCESS"
export const SETID = "ON_SET_ID"