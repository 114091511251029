import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { auth, db } from "../../../firebase.config";
import { showToast } from "../../../utils/toast";

const BankDetailsForm = () => {
  const [cheque, setcheque] = useState();
  const [data, setdata] = useState({});
  const [bank, setbank] = useState({});
  const [user, setuser] = useState();
  const useruid = useSelector((state) => {
    return state.getter.uid;
  });
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setuser(_tmp);
        });
      }
    });
  }, []);
  useEffect(() => {
    if (useruid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        const _tmp = doc.data();
        setbank(_tmp?.bankDetails);
        setdata(_tmp);
      });

      onSnapshot(doc(db, "documents", useruid), (doc) => {
        const _tmp = doc.data();
        setcheque(_tmp?.cancelledCheque);
      });
    }
  }, [useruid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (useruid) {
      if (
        (bank?.bankAccountNumber &&
          bank?.bankAccountType &&
          bank?.bankBranchAddress &&
          bank?.bankBranchName &&
          bank?.bankName &&
          bank?.cancelCheque) ||
        (cheque && bank?.ifscCode)
      ) {
        await updateDoc(doc(db, "users", useruid), { "forms.bankdetails": 2 });
        showToast("success", "Bank Details Validated!");
      } else {
        showToast("warning", "Form is Incomplete!");
      }
    }
  };

  const printDivRef = useRef();

  return (
    <>
      <div
        id="maindiv"
        className="pt-3 w-full h-full rounded-lg border-gray-800"
      >
        <div
          ref={printDivRef}
          id="printref"
          className="p-10 pt-0 lg:w-full m-auto align-center rounded-lg border-gray-800 glass"
        >
          <h3 className="p-3 text-white text-center h3 border-bottom border-secondary break-all mb-4">
            Vendor Bank Details
          </h3>
          <form onSubmit={(e) => handleSubmit(e)}>
            <p className="h4 text-white border-bottom pb-2 mb-3">
              User Details
            </p>
            <div className="row">
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Name of the Beneficiary
                </label>
                <input
                  type="text"
                  value={data?.name?.toUpperCase()}
                  disabled
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Contact Number
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={data?.phone}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  PAN Number
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={data?.pancard}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Email
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={data?.email}
                  disabled
                />
              </div>
              <div className="mb-3 col-8">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Address
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={data?.address}
                  disabled
                />
              </div>
            </div>
            <p className="h4 text-white border-bottom pb-2 mb-3">
              Bank Details
            </p>
            <div className="row">
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Bank Name
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.bankName}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Bank Branch Name
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.bankBranchName}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Bank Account Type
                </label>
                <select
                  disabled
                  value={bank?.bankAccountType}
                  className="glass text-gray-300 shadow-none border-none text-sm rounded-lg block w-full p-2.5 placeholder-gray-700"
                  aria-label="Default select"
                >
                  <option selected value="" disabled>
                    Select
                  </option>
                  <option value="Savings">Savings</option>
                  <option value="Current">Current</option>
                </select>
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Bank Account Number
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.bankAccountNumber}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  MICR Code <small>(All Digits)</small>
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.bankMICR}
                  disabled
                />
              </div>
              <div className="mb-3 col-4">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  IFSC Code <small>(All Digits)</small>
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.ifscCode}
                  disabled
                />
              </div>
              <div className="mb-3 col-6">
                <label className="block mb-2 text-sm font-medium text-gray-300">
                  Bank Address
                </label>
                <input
                  type="text"
                  className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bank?.bankBranchAddress}
                  disabled
                />
              </div>
              <div className="mb-3 col-6">
                <label className="block mb-3 text-sm font-medium text-gray-300">
                  Cancelled Cheque or Bank Certificate
                </label>
                {cheque ? (
                  <a
                    href={cheque}
                    target="_blank"
                    className="rounded-lg bg-blue-800 text-white p-2 px-4"
                  >
                    View
                  </a>
                ) : (
                  <p className="text-gray-50">Document not Uploaded</p>
                )}
              </div>
            </div>
            {user?.usertype != "auditor" ? (
              <>
                {data?.forms?.bankdetails == 1 && (
                  <button
                    type="submit"
                    className="rounded-lg bg-blue-800 text-white mt-3 w-full p-2 disabled:bg-blue-900"
                    disabled={data?.forms?.bankdetails >= 2}
                  >
                    Validate
                  </button>
                )}
                {data?.forms?.bankdetails >= 2 ? (
                  <>
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button
                            id="printBtn"
                            className="rounded-lg bg-blue-800 text-white w-full p-2 my-3"
                          >
                            Print
                          </button>
                        );
                      }}
                      onBeforeGetContent={() => {
                        // document.getElementById('maindiv').classList.remove('glass');
                        document
                          .getElementById("printref")
                          .classList.remove("glass");
                        document
                          .getElementById("printBtn")
                          .classList.add("d-none");
                      }}
                      onAfterPrint={() => {
                        // document.getElementById('maindiv').classList.add('glass');
                        document
                          .getElementById("printref")
                          .classList.add("glass");
                        document
                          .getElementById("printBtn")
                          .classList.remove("d-none");
                      }}
                      content={() => printDivRef.current}
                      documentTitle="Vendor Data Form"
                      pageStyle="print"
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default BankDetailsForm;
