import { db } from '../../firebase.config';
import { getDocument } from '../../utils/api';
import { SETID } from '../reducers/getterReducer';
import { collection, doc, onSnapshot } from "firebase/firestore";

export const onSetID = (id) => {
    return async (dispatch) => {
        try {
            dispatch(onSetIDSuccess(id));
        } catch (err) {
            console.log(err);
        }
    }
}

const onSetIDSuccess = (data) => {
    return {
        type: SETID,
        payload: data
    }
}
