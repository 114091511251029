/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../firebase.config";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
  deleteDoc
} from "firebase/firestore";
import 'firebase/auth';
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch } from "react-redux";
import { onSetID } from "../../store/actions/getterAction";
import { epochToTimestamp, isEmpty } from "../../utils/utils";
import { Spinner } from "../reusable/spinner/Spinner";
import InviteIntroducer from "./inviteIntroducer";
import { onAuthStateChanged } from "firebase/auth";
import { showToast } from "../../utils/toast";
import { BASE_URL, BASE_URL_LOCAL, updateDocument } from "../../utils/api";
import ReusableModal from "../reusable/modal/reusablemodal";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { utils, writeFile } from "xlsx";
import axios, { all } from "axios";
import saveAs from "file-saver";

const Dashboard = () => {
  const [allusers, setallusers] = useState([]);
  const [alldocs, setalldocs] = useState([]);
  const [gstallusers, setgstusers] = useState([]);
  const [master, setmaster] = useState([]);
  const [load, setload] = useState(true);
  const [user, setUser] = useState();
  const [item, setitem] = useState({});
  const [usertype, settype] = useState("");
  const [gstquery, setquery] = useState();
  const [recall, setrecall] = useState(false);
  const [deleterecall, setdeleterecall] = useState(false);
  const [showModal, setshowmodal] = useState(false);
  const [showQuery, setshowQuery] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const target = useRef(null);
  const queryRef = useRef();

  useEffect(() => {
    if (user && user?.uid) {
      onSnapshot(doc(db, "users", user?.uid), (doc) => {
        var _tmp = doc.data();
        console.log(_tmp);
        settype(_tmp?.usertype);
      });
    }
  }, [user]);

  useEffect(() => {
    onAuthStateChanged(auth, (User) => {
      if (User) {
        setUser(User);
      } else {
        setUser();
      }
    });

    var userlist = [];
    var gstusers = [];
    var docs = [];
    const docRef = collection(db, "users");
    const documentsRef = collection(db, "documents");
    onSnapshot(docRef, (docsSnap) => {
      userlist = [];
      gstusers = [];
      docsSnap.forEach((doc) => {
        userlist.push(doc.data());
        gstusers.push(doc.data());
        userlist = userlist.filter(
          (ul) =>
            ul.usertype != "admin" &&
            ul.usertype != "gst" &&
            ul.usertype != "auditor"
        );
        gstusers = userlist.filter((ul) => ul.usertype == "vendor");
        setgstusers(gstusers);
        setallusers(userlist);
        if (usertype === "gst") {
          setmaster(gstusers);
        } else {
          setmaster(userlist);
        }
        setload(false);
      });
    });
    onSnapshot(documentsRef, (docsSnap) => {
      docsSnap.forEach((doc) => {
        let __tmp = {
          uid: doc.id,
          ...doc.data()
        }
        docs.push(__tmp);
      });
      setalldocs(docs);
    });
  }, []);

  createTheme("solarized", {
    text: {
      primary: "#FFFFFF",
    },
    background: {
      default: "rgba(255, 255, 255, 0.07)",
    },
  });

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        backgroundColor: "rgba(255,255,255,0.8)",
        backdropFilter: "blur(15px)",
        color: "black",
        borderRadiusTopLeft: "10px",
      },
    },
  };

  const vendorStatus = (status) => {
    if (status === 0) {
      return "Forms Pending";
    }
    if (status === 1) {
      return "Forms Submitted";
    }
    if (status === 2) {
      return "Forms Validated";
    }
    if (status === 3) {
      return "Sent for Approval";
    }
    if (status === 4) {
      return "Approved";
    }
    if (status === 5) {
      return "Approved as Exception";
    }
    if (status === "" || status === undefined) {
      return "NA";
    }
  };

  const columns = [
    {
      name: "User ID",
      selector: (row) => row.userid,
      sortable: true,
      width: "130px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "User Type",
      selector: (row) => row.usertype?.toUpperCase(),
      sortable: true,
      width: "130px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Vendor Type",
      selector: (row) => isEmpty(row.vendortype?.toUpperCase()),
      sortable: true,
      width: "170px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Vendor Code",
      selector: (row) => isEmpty(row.system_code?.toUpperCase()),
      sortable: true,
      width: "170px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "PAN Card",
      selector: (row) => isEmpty(row.pancard),
      sortable: true,
      width: "150px",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Email",
      width: "180px",
      selector: (row) => row.email,
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Introducer Name",
      width: "220px",
      selector: (row) => isEmpty(row.fpoc),
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Approved On",
      width: "220px",
      selector: (row) => isEmpty(row?.approvedOn?.seconds || row?.approvedAsExceptionOn?.seconds || row?.denyedOn?.seconds ? epochToTimestamp(row?.approvedOn?.seconds || row?.approvedAsExceptionOn?.seconds || row?.denyedOn?.seconds, false) : "-"),
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Status",
      selector: (row) => vendorStatus(row.forms?.status),
      width: "150px",
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Action",
      selector: (row) => (usertype === "admin" || usertype === undefined || usertype === null) ? <div onClick={() => {
        handleDelete(row)
      }}><i className={`fa-solid fa-trash`}></i></div> : "-",
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleDelete = (userData) => {
    try {
      setDeleteUser(userData);
      setDeleteConfirm(true);
    } catch (e) {
      console.log(e);
    }
  }

  const gstcolumns = [
    {
      name: "User ID",
      selector: (row) => row.userid,
      sortable: true,
      width: "120px",
    },
    {
      name: "Vendor Type",
      selector: (row) => row.vendortype?.toUpperCase(),
      sortable: true,
      width: "160px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "GSTIN Number",
      cell: (row) => (
        <>
          {row.GSTIN ? (
            <div className="d-flex justify-center align-middle text-center">
              <CopyToClipboard
                text={row.GSTIN}
                onCopy={() => showToast("success", "GTSIN Number Copied")}
              >
                <span className="cursor-pointer" title="Copy to Clipboard">
                  {row.GSTIN}
                  <i className="fa-regular fa-copy ms-1 fa-lg" />
                </span>
              </CopyToClipboard>
            </div>
          ) : (
            "NA"
          )}
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "GST Filing",
      cell: (row) => (
        <select
          className="text-gray-50 cursor-pointer text-center bg-gray-500 shadow-none border-none text-sm rounded-lg outline-none block w-full p-1.5 placeholder-gray-700"
          onChange={(e) => setHandleGSTFiling(e.target.value, row?.uid)}
          title="Choose Filing Status"
          value={row?.gst?.status}
        >
          <option selected value="">
            Choose Filing Status
          </option>
          <option value="regular">Regular</option>
          <option value="irregular">Irregular</option>
        </select>
      ),
      width: "120px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="rounded-lg bg-gray-500 text-gray-50 w-full p-2 disabled:bg-gray-600 disabled:text-gray-300"
          onClick={() => {
            setshowQuery(true);
            setitem(row);
          }}
          disabled={row?.gst?.status === "regular" || row?.gst?.status === ""}
        >
          {" "}
          Add Query
        </button>
      ),
      width: "120px",
    },
    {
      name: (
        <p>
          Remarks
          <OverlayTrigger
            trigger={["hover", "click"]}
            placement="top"
            overlay={
              <Popover
                className="px-2 py-1"
                id="popover-positioned-top"
                title="Popover top"
              >
                <p className="text-xs font-bold m-0">
                  Hover or click on remark to view
                </p>
              </Popover>
            }
          >
            <span>
              <i className="fa-solid fa-circle-question ms-1 fa-sm"></i>
            </span>
          </OverlayTrigger>
        </p>
      ),
      cell: (row) => (
        <>
          <OverlayTrigger
            trigger={["hover", "click"]}
            placement="bottom"
            overlay={
              <Popover
                className="p-2"
                id="popover-positioned-bottom"
                title="Popover bottom"
              >
                {row?.gst?.remark ? row?.gst?.remark : "NA"}
              </Popover>
            }
          >
            <p className="font-medium rounded-lg text-sm text-truncate">
              {row?.gst?.remark ? row?.gst?.remark : "NA"}
            </p>
          </OverlayTrigger>
        </>
      ),
      width: "230px",
    },
  ];

  const setHandleGSTFiling = async (e, uid) => {
    console.log(e);
    if (e === "regular") {
      await updateDocument("users", uid, { gst: { status: e, remark: "" } });
      showToast("success", "GST Status changed Successfully!");
      return;
    }
    await updateDocument("users", uid, { "gst.status": e });
    showToast("success", "GST Status changed Successfully!");
    return;
  };

  const handleUser = (event) => {
    dispatch(onSetID(event.uid));
    navigate(`/user`);
  };

  const handleSearch = (keyword) => {
    const search = keyword;
    if (search === "") {
      if (usertype === "gst") {
        setgstusers(master.filter((m) => m.usertype === "vendor"));
        return;
      } else {
        setallusers(master.filter((m) => m.usertype !== "admin"));
        return;
      }
    }

    const filteredList = allusers.filter((user) => {
      return (
        user?.name?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
        user?.email?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
        user?.userid?.toLowerCase().indexOf(search?.toLowerCase()) > -1
      );
    });

    const filteredgstList = gstallusers.filter((user) => {
      return (
        user?.name?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
        user?.email?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
        user?.userid?.toLowerCase().indexOf(search?.toLowerCase()) > -1
      );
    });
    setgstusers(filteredgstList);
    setallusers(filteredList);
  };

  const handleChangeUsertype = (key) => {
    const q = query(collection(db, "users"), where("usertype", "==", key));
    onSnapshot(q, (querySnapshot) => {
      let arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
        setallusers(arr);
        setload(false);
      });
    });
    if (key == "all") {
      var userlist = [];
      const docRef = collection(db, "users");
      onSnapshot(docRef, (docsSnap) => {
        docsSnap.forEach((doc) => {
          userlist.push(doc.data());
          userlist = userlist.filter(
            (ul) => ul.usertype != "admin" && ul.usertype != "gst"
          );
          setallusers(userlist);
          setload(false);
        });
      });
    }
  };

  const handleChangeVendortype = (key) => {
    if (usertype === "gst") {
      if (key == "" || key == "all") {
        let filtered = master.filter((inv) => inv.usertype == "vendor");
        setgstusers(filtered);
        return;
      }
      if (key) {
        let filtered = master.filter(
          (inv) => inv.vendortype === key && inv.usertype == "vendor"
        );
        setgstusers(filtered);
        return;
      }
    } else {
      setallusers(master);
      if (key == "" || key == "all") {
        let filtered = master.filter((inv) => inv.usertype == "vendor");
        setallusers(filtered);
        return;
      }
      if (key) {
        let filtered = master.filter((inv) => inv.vendortype === key);
        setallusers(filtered);
        return;
      }
    }
  };

  useEffect(() => {
    if (recall === true) {
      const asyncfunc = async () => {
        console.log("gstquery", gstquery);
        setquery("");
        await updateDocument("users", item?.uid, { "gst.remark": gstquery });
        showToast("success", `Remark added for ${item?.name} Successfully!`);
        return;
      };
      asyncfunc();
      // queryRef.current.value = "";
    }
  }, [recall]);

  useEffect(() => {
    if (deleterecall === true) {
      const asyncfunc = async () => {
        await axios
          .post(BASE_URL + "/api/mail/deleteuser", { uid: deleteUser?.uid })
          .then(async (res) => {
            if (res?.data?.code == 0) {
              await deleteDoc(doc(db, "users", deleteUser?.uid));
              showToast("success", res?.message);
            } else {
              showToast("error", res?.message);
            }
          })
          .catch((err) => {
            console.log(err);
            showToast("warning", err.message);
          });
        setDeleteUser(null);
        setdeleterecall(false);
        return;
      };
      asyncfunc();
      // queryRef.current.value = "";
    }
  }, [deleterecall]);

  const getDocs = (uid) => {
    try {
      let _data = alldocs?.filter((doc) => doc?.uid == uid);
      if (_data && _data?.length > 0) {
        let _obj = { ..._data[0] };
        delete _obj.logs;
        delete _obj.uid;
        return {
          ..._obj
        };
      } else {
        return "NA";
      }
    } catch (e) {

    }
  }

  const handleExportData = () => {
    var user = [],
      gst = [];
    if ((usertype && allusers) || gstallusers) {
      if (usertype === "gst") {
        for (var i = 0; i < gstallusers.length; i++) {
          let dump = {
            usertype: isEmpty(gstallusers[i].usertype),
            vendortype: isEmpty(gstallusers[i].vendortype),
            name: isEmpty(gstallusers[i].name),
            phone: isEmpty(gstallusers[i].phone),
            designation: isEmpty(gstallusers[i].designation),
            address: isEmpty(gstallusers[i].address),
            email: isEmpty(gstallusers[i].email),
            aadharcard: isEmpty(gstallusers[i].aadharcard),
            pancard: isEmpty(gstallusers[i].pancard),
            introducer_name: isEmpty(gstallusers[i].fpoc),
            introducer_email: isEmpty(gstallusers[i].fpocno),
            gstin: isEmpty(gstallusers[i].GSTIN),
            gststatus: isEmpty(gstallusers[i].gst?.status),
            gstremark: isEmpty(gstallusers[i].gst?.remark),
            empanelmentStatus: isEmpty(
              vendorStatus(gstallusers[i].forms?.status)
            ),
            systemcode: isEmpty(gstallusers[i]?.system_code),
            approvedon: isEmpty(gstallusers[i]?.approvedOn?.seconds || gstallusers[i]?.approvedAsExceptionOn?.seconds || gstallusers[i]?.denyedOn?.seconds ? epochToTimestamp(gstallusers[i]?.approvedOn?.seconds || gstallusers[i]?.approvedAsExceptionOn?.seconds || gstallusers[i]?.denyedOn?.seconds, false) : "-")
          };
          gst.push(dump);
        }
        let wb = utils.book_new(),
          ws = utils.json_to_sheet(gst);
        utils.book_append_sheet(wb, ws, "All GST Users List");
        writeFile(wb, "GST_Users_List.xlsx");
      } else {
        for (var k = 0; k < allusers.length; k++) {
          let dump = {
            usertype: isEmpty(allusers[k].usertype),
            vendortype: isEmpty(allusers[k].vendortype),
            name: isEmpty(allusers[k].name),
            phone: isEmpty(allusers[k].phone),
            designation: isEmpty(allusers[k].designation),
            address: isEmpty(allusers[k].address),
            email: isEmpty(allusers[k].email),
            aadharcard: isEmpty(allusers[k].aadharcard),
            pancard: isEmpty(allusers[k].pancard),
            introducer_name: isEmpty(allusers[k].fpoc),
            introducer_email: isEmpty(allusers[k].fpocno),
            gstin: isEmpty(allusers[k].GSTIN),
            gststatus: isEmpty(allusers[k].gst?.status),
            gstremark: isEmpty(allusers[k].gst?.remark),
            empanelmentStatus: isEmpty(vendorStatus(allusers[k].forms?.status)),
            systemcode: isEmpty(allusers[k]?.system_code),
            approvedon: isEmpty(allusers[k]?.approvedOn?.seconds || allusers[k]?.approvedAsExceptionOn?.seconds || allusers[k]?.denyedOn?.seconds ? epochToTimestamp(allusers[k]?.approvedOn?.seconds || allusers[k]?.approvedAsExceptionOn?.seconds || allusers[k]?.denyedOn?.seconds, false) : "-"),
            ...getDocs(allusers[k]?.uid)
          };
          user.push(dump);
        }
        let wb = utils.book_new(),
          ws = utils.json_to_sheet(user),
          dldoc = utils.json_to_sheet(user);
        utils.book_append_sheet(wb, ws, "All Users List");



        utils.book_append_sheet(wb, dldoc, "Documents");
        writeFile(wb, "All_Users_List.xlsx");
      }
    }

    // Following code for 2 sheets, all users and gst users, if required just uncomment below and commect above

    // var wb = utils.book_new(),
    //     gws = utils.json_to_sheet(gstallusers),
    //     aws = utils.json_to_sheet(allusers);
    // utils.book_append_sheet(wb, aws, "Users List");
    // utils.book_append_sheet(wb, gws, "GST Users List");
    // writeFile(wb, "Vendor_Portal_Users_List.xlsx");
  };

  const handleDownloadPDF = () => {
    console.log("first");
    axios
      .post(
        `${BASE_URL_LOCAL}/api/pdf/generatePDF?uid=ksMThtgwtIVl3re0AYuSIwZPwsq2&file=bank`
      )
      .then(() =>
        axios
          .post(
            `${BASE_URL_LOCAL}/api/pdf/downloadPDF?uid=ksMThtgwtIVl3re0AYuSIwZPwsq2`,
            { "Content-Type": "multipart/form-data" },
            { responseType: "arraybuffer" }
          )
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });
            saveAs(pdfBlob, "bank_details.pdf");
          })
      );
  };

  return (
    <>
      <div
        className="p-4 w-full h-full rounded-lg border-gray-800 shadow-md"
        style={{ height: "calc(100vh - 4.5em)" }}
      >
        <div className="p-10 pt-4 w-full m-auto align-center rounded-lg border-gray-800 glass">
          <div className="text-white h1 break-all text-center border-b pb-2">
            Users
          </div>
          <div
            className={
              usertype === "gst"
                ? "d-flex justify-content-between align-items-center border-b pb-2.5"
                : "d-flex justify-content-between align-items-center border-b pb-2.5"
            }
          >
            {usertype === "gst" ? (
              <select
                className="mt-1 me-2 text-gray-50 bg-gray-500 shadow-none border-none text-sm rounded-lg outline-none block w-44 p-2.5 placeholder-gray-700"
                onChange={(e) => handleChangeVendortype(e.target.value)}
              >
                <option selected value="">
                  Choose Vendor Type
                </option>
                <option value="all">All</option>
                <option value="Overhead">Overhead</option>
                <option value="Digital">Digital</option>
                <option value="Non-Digital">Non-Digital</option>
                <option value="Content">Content</option>
              </select>
            ) : (
              <div className="d-flex">
                <select
                  className="mt-1 me-2 text-gray-50 bg-gray-500 shadow-none border-none text-sm rounded-lg outline-none block w-40 p-2.5 placeholder-gray-700"
                  onChange={(e) => handleChangeUsertype(e.target.value)}
                >
                  <option selected value="" disabled>
                    Choose User Type
                  </option>
                  <option value="all">All Users</option>
                  <option value="introducer">Introducers</option>
                  <option value="vendor">Vendors</option>
                  <option value="trainee">Trainee</option>
                </select>
                <select
                  className="mt-1 me-2 text-gray-50 bg-gray-500 shadow-none border-none text-sm rounded-lg outline-none block w-44 p-2.5 placeholder-gray-700"
                  onChange={(e) => handleChangeVendortype(e.target.value)}
                >
                  <option selected value="">
                    Choose Vendor Type
                  </option>
                  <option value="all">All</option>
                  <option value="Overhead">Overhead</option>
                  <option value="Digital">Digital</option>
                  <option value="Non-Digital">Non-Digital</option>
                  <option value="Content">Content</option>
                </select>
              </div>
            )}

            <div className="d-flex flex-row">
              <input
                name="searchQuery"
                className="glass rounded-l-lg w-80 shadow-none border-none text-gray-300 text-sm focus:ring-blue-500 outline-none block p-2.5 dark:bg-gray-700 placeholder-gray-400"
                autoComplete="none"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search with Full Name, Email and User ID"
              />
              <button
                className="ms-2 w-36 text-gray-50 p-2.5 bg-blue-600 rounded-lg text-sm"
                onClick={() => handleExportData()}
              >
                <i className="fa-solid fa-download" /> Export as XLSX{" "}
              </button>
              {usertype === "admin" ||
                usertype === undefined ||
                usertype === null ? (
                <button
                  className="ms-2 text-gray-50 p-2.5 bg-blue-600 rounded-lg text-sm"
                  onClick={() => setshowmodal(true)}
                  title="Add Auditor"
                >
                  <i className="fa-solid fa-plus" />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <button className='btn btn-primary rounded-lg w-100' onClick={() => handleDownloadPDF()}>Download PDF</button> */}
          <div className="mt-3">
            {usertype === "gst" ? (
              <DataTable
                columns={gstcolumns}
                data={gstallusers}
                responsive
                highlightOnHover
                fixedHeader
                theme="solarized"
                customStyles={customStyles}
                progressPending={load}
                progressComponent={<Spinner className="m-5" loader={true} />}
                fixedHeaderScrollHeight="500px"
              />
            ) : (
              <DataTable
                columns={columns}
                data={allusers}
                responsive
                highlightOnHover
                fixedHeader
                theme="solarized"
                customStyles={customStyles}
                progressPending={load}
                progressComponent={<Spinner className="m-5" loader={true} />}
                fixedHeaderScrollHeight="500px"
                onRowClicked={(row) => handleUser(row)}
              />
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <InviteIntroducer
          show={showModal}
          onClose={() => setshowmodal(false)}
        />
      )}
      {showQuery && (
        <ReusableModal
          modalTitle={`GST Query for ${item?.name}`}
          confirmButton="Submit"
          cancelButtom="Cancel"
          subtitle={`Note: Query will be saved in the name of ${item?.name} and can be viewed by admin`}
          recall={recall}
          setrecall={setrecall}
          show={showQuery}
          onClose={() => {
            setshowQuery(false);
          }}
          content={
            <>
              <div className="row">
                <div className="col-12">
                  <label className="text-gray-50 mb-2">Query</label>
                  <textarea
                    ref={queryRef}
                    className="glass w-100 shadow-none text-gray-50"
                    cols={10}
                    rows={5}
                    value={gstquery}
                    onChange={(e) => setquery(e.target.value)}
                  />
                </div>
              </div>
            </>
          }
        />
      )}
      {deleteConfirm && (
        <ReusableModal
          modalTitle={`Delete User ${deleteUser.name}?`}
          confirmButton="Delete"
          cancelButtom="Cancel"
          subtitle={`Note: After clicking on delete user ${deleteUser.name} will be deleted from the system`}
          recall={deleterecall}
          setrecall={setdeleterecall}
          show={deleteConfirm}
          onClose={() => {
            setDeleteConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default Dashboard;
