import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../../firebase.config";
import GSTTable from "./gstTable";
import PartyDisclosure from "./partyDisclosure";
import { showToast } from "../../../utils/toast";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

const TraineeDetails = () => {
  const [data, setdata] = useState();
  const [generalObj, setgeneralobj] = useState({});
  const [generalList, setgenerallist] = useState([]);
  const [accountsDetails, setaccountsdetails] = useState({});
  const [salesDetails, setsalesdetails] = useState({});
  const [makerDetails, setmakerdetails] = useState({});
  const [secOne, setSecOne] = useState([]);
  const [secFive, setSecFive] = useState([]);
  const [secSix, setSecSix] = useState([]);
  const [isEditable, setisEditable] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    sectionOne: {},
    sectionTwo: {},
    sectionThree: {},
    sectionFour: {},
    sectionFive: {},
    sectionSix: {},
  });
  const [user, setUser] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setUser(_tmp);
        });
      }
    });
  }, []);
  const pdfref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfref.current,
    onBeforeGetContent: () => {
      document.getElementById("printbtn").classList.add("d-none");
      document.getElementById("printDiv").classList.remove("glass");
      document.getElementById("collapseSectionOne").classList.add("show");
      document.getElementById("collapseSectionTwo").classList.add("show");
      document.getElementById("collapseSectionFour").classList.add("show");
      document.getElementById("collapseSectionSix").classList.add("show");
      // document.getElementById('disableDiv').classList.add('d-none');
    },
    onAfterPrint: () => {
      document.getElementById("printbtn").classList.remove("d-none");
      document.getElementById("printDiv").classList.add("glass");
      document.getElementById("collapseSectionOne").classList.remove("show");
      document.getElementById("collapseSectionTwo").classList.remove("show");
      document.getElementById("collapseSectionFour").classList.remove("show");
      document.getElementById("collapseSectionSix").classList.remove("show");
      // document.getElementById('disableDiv').classList.remove('d-none');
    },
  });

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  const [gstRows, setGSTData] = useState([
    {
      gstStateCode: "",
      gstNo: "",
      billingAddress: "",
      serviceName: "",
      sacCode: "",
      contactDetails: "",
    },
  ]);

  const [partyRows, setPartyRows] = useState([
    {
      vendorPartyName: "",
      vendorRole: "",
      vendorInterestedPartyName: "",
      detailsOfInterest: "",
    },
  ]);

  const scroll = (search) => {
    if (search.includes("One")) {
      window.scrollBy(0, 550);
    }
    if (search.includes("Two")) {
      window.scrollBy(0, 622);
    }
    if (search.includes("Three")) {
      window.scrollBy(0, 700);
    }
    if (search.includes("Four")) {
      window.scrollBy(0, 760);
    }
    if (search.includes("Five")) {
      window.scrollBy(0, 830);
    }
    if (search.includes("Six")) {
      window.scrollBy(0, 900);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const path = window.location.hash.replace("#", "");
      let accord = document.getElementById(path);
      const isCollapse = accord.classList.contains("collapse");
      if (isCollapse) {
        accord.classList.remove("collapse");
        accord.classList.add("collapsed");
        scroll(path);
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    const keyPersonal = {
      accountsDetails,
      salesDetails,
      makerDetails,
    };
    setSecOne({
      ...secOne,
      ...keyPersonal,
    });
  }, [accountsDetails, salesDetails, makerDetails]);

  useEffect(() => {
    setSecOne({
      ...secOne,
      gstTable: gstRows,
    });
  }, [gstRows]);

  useEffect(() => {
    setSecFive({
      ...secFive,
      partyTable: partyRows,
    });
  }, [partyRows]);

  // Form data save functions

  const handleSetState = (e, section) => {
    if (e.target.value) {
      if (section === 1) {
        setSecOne({
          ...secOne,
          [e.target.name]: e.target.value,
        });
      }
      if (section === 5) {
        setSecFive({
          ...secFive,
          [e.target.name]: e.target.value,
        });
      }
      if (section === 6) {
        setSecSix({
          ...secSix,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    if (
      generalObj?.companyName != undefined &&
      generalObj?.refName != undefined &&
      generalObj?.refNumber != undefined &&
      typeof generalObj?.refNumber == "string" &&
      generalObj?.refNumber?.includes(".com")
    ) {
      setgenerallist([...generalList, generalObj]);
      setgeneralobj({});
    }
  }, [generalObj]);

  useEffect(() => {
    if (generalList.length > 0) {
      setSecOne({
        ...secOne,
        generalQuestions: generalList,
      });
    }
  }, [generalList]);

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (useruid) {
      if (data?.forms?.vendorform === 1) {
        await updateDoc(doc(db, "users", useruid), { "forms.vendorform": 2 });
        showToast("success", "Vendor Form Validated!");
      } else {
        showToast("warning", "Form is Incomplete!");
      }
    }
  };

  // to get the prefilled form

  useEffect(() => {
    if (useruid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        if (
          doc.data().vendorDetails &&
          doc.data().vendorDetails != {} &&
          doc.data().vendorDetails != undefined
        ) {
          setisEditable(true);
          const allData = doc.data()?.vendorDetails;
          setdata(doc.data());
          setVendorDetails(allData);
          setSecOne(allData?.sectionOne);
          setSecFive(allData?.sectionFive);
          setSecSix(allData?.sectionSix);
          setaccountsdetails(allData?.sectionOne?.accountsDetails);
          setsalesdetails(allData?.sectionOne?.salesDetails);
          setmakerdetails(allData?.sectionOne?.makerDetails);
          setGSTData(allData?.sectionOne?.gstTable);
          setPartyRows(allData?.sectionFive?.partyTable);
        }
      });
    }
  }, [useruid]);

  return (
    <>
      <div
        ref={pdfref}
        id="printDiv"
        className="px-4 mt-3 w-full rounded-lg border-gray-800 glass"
      >
        <h3 className="text-white p-4 text-center text-uppercase lg:text-3xl md:text-xl">
          Vendor Registration Form
        </h3>
        <div
          className="accordion accordion-flush text-white"
          id="accordionExample"
        >
          {/* Section One */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secOne">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionOne"
                aria-expanded="true"
                aria-controls="collapseSectionOne"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 1 - Vendor Information Details
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionOne"
              className="accordion-collapse collapse"
              aria-labelledby="secOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="my-3">
                  <div className="row mt-2 ">
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Name of the Vendor
                      </label>
                      <input
                        type="text"
                        name="nameOfVendor"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.nameOfVendor}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 1)
                      </label>
                      <input
                        type="text"
                        name="addressL1"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL1}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 2)
                      </label>
                      <input
                        type="text"
                        name="addressL2"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL2}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 3)
                      </label>
                      <input
                        type="text"
                        name="addressL3"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL3}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.city}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Pin/Zip/Post Code
                      </label>
                      <input
                        type="text"
                        name="pincode"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.pincode}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        name="country"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.country}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Telephone number with area code:
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.telephone}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Vendor status:
                      </label>
                      <select
                        defaultValue=""
                        name="vendorStatus"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.vendorStatus}
                        disabled
                        className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        aria-label="Default select"
                      >
                        <option selected value="" disabled>
                          Select
                        </option>
                        <option value="Public Limited Company">
                          Public Limited Company
                        </option>
                        <option value="Private Limited Company">
                          Private Limited Company
                        </option>
                        <option value="Partnership Firm">
                          Partnership Firm
                        </option>
                        <option value="Sole Proprietor Firm">
                          Sole Proprietor Firm
                        </option>
                        <option value="Local Inter Company">
                          Local Inter Company
                        </option>
                        <option value="Foreign Inter Company">
                          Foreign Inter Company
                        </option>
                        <option value="Individual(Moderator/Freelancer)">
                          Individual(Moderator/Freelancer)
                        </option>
                        <option value="Hindu Undivided Family">
                          Hindu Undivided Family
                        </option>
                        <option value="Employee">Employee</option>
                        <option value="Association of Persons">
                          Association of Persons
                        </option>
                        <option value="Trust and Body of Individuals">
                          Trust and Body of Individuals
                        </option>
                      </select>
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Aadhar Card number
                      </label>
                      <input
                        type="text"
                        name="registrationNumber"
                        onChange={(e) => handleSetState(e, 1)}
                        value={data?.aadharcard}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <h3 className="text-white text-start text-uppercase text-lg">
                      Statute Data
                    </h3>
                    <div className="mt-2 row">
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          PAN No. (provide a copy of the PAN Card)
                        </label>
                        <input
                          type="text"
                          name="panno"
                          onChange={(e) => handleSetState(e, 1)}
                          value={data?.pancard}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Withholding taxes will be deducted as per the
                          prevailling applicable rates basis the tax
                          declarations/certificates provided to us
                        </label>
                        <input
                          type="text"
                          name="withHoldingTaxes"
                          onChange={(e) => handleSetState(e, 1)}
                          value="Yes"
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Related Party with Omnicom Media Group (Yes/No)
                        </label>
                        <select
                          defaultValue=""
                          name="relatedwOMG"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.relatedwOMG}
                          disabled={isEditable}
                          className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          aria-label="Default select"
                        >
                          <option selected value="" disabled>
                            Select
                          </option>
                          <option selected value="Yes">
                            Yes
                          </option>
                          <option selected value="No">
                            No
                          </option>
                        </select>
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          If Related Party with Omnicom Media Group is yes
                          please mention type of relationship
                        </label>
                        <input
                          type="text"
                          name="panno"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.relatedwOMGRelation}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section Two */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secTwo">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionTwo"
                aria-expanded="true"
                aria-controls="collapseSectionTwo"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 2 - Vendor Bank Information
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionTwo"
              className="accordion-collapse collapse"
              aria-labelledby="secTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <p className="font-light text-justify">
                  <strong>
                    <i className="fa-solid fa-circle-info" />
                  </strong>
                  Click here to{" "}
                  <Link to="/bankdetails" className="text-primary underline">
                    view
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Section Four */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secFour">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionFour"
                aria-expanded="true"
                aria-controls="collapseSectionFour"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 3 - Document Checklist
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionFour"
              className="accordion-collapse collapse"
              aria-labelledby="secFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <p className="font-light text-justify">
                  <strong>
                    <i className="fa-solid fa-circle-info" />
                  </strong>
                  Click here to{" "}
                  <Link to="/user" className="text-primary underline">
                    view
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Section Six */}
          <div className="accordion-item mt-2 mb-4 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secSix">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionSix"
                aria-expanded="true"
                aria-controls="collapseSectionSix"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 4 - Declaration
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionSix"
              className="accordion-collapse collapse"
              aria-labelledby="secSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="row">
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Name of Service Provider
                    </label>
                    <input
                      type="text"
                      name="serviceProvider"
                      value={secSix?.serviceProvider}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Name of Signatory
                    </label>
                    <input
                      type="text"
                      name="signatoryName"
                      value={secSix?.signatoryName}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Position of Signatory
                    </label>
                    <input
                      type="text"
                      name="positionOfSignatory"
                      value={data?.designation}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Contact Number
                    </label>
                    <input
                      type="text"
                      name="signatoryContactNumber"
                      value={data?.phone}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Email ID
                    </label>
                    <input
                      type="text"
                      name="signatoryEmail"
                      value={data?.email}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Place
                    </label>
                    <input
                      type="text"
                      name="place"
                      value={secSix?.place}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="date"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      name="signatoryDate"
                      value={secSix?.signatoryDate}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user?.usertype != "auditor" ? (
            <>
              {data?.forms?.vendorform === 1 ? (
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="rounded-lg bg-blue-800 text-white w-full my-3 p-2 disabled:bg-blue-900"
                  disabled={data?.forms?.vendorform == 2}
                >
                  {data?.forms?.vendorform == 2 ? "Validated" : "Validate"}
                </button>
              ) : (
                <button
                  id="printbtn"
                  onClick={() => handlePrint()}
                  className="rounded-lg bg-blue-800 text-white w-full my-3 p-2 disabled:bg-blue-900"
                >
                  Print
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TraineeDetails;
