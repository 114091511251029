import { doc, onSnapshot, arrayUnion } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable, { createTheme } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase.config";
import {
  epochToTimestamp,
  getStatus,
  getStatusIcon,
  isEmpty,
} from "../../utils/utils";
import { BASE_URL, BASE_URL_LOCAL, updateDocument } from "../../utils/api";
import { showToast } from "../../utils/toast";
import { SmallSpinner } from "../reusable/spinner/Spinner";
import axios from "axios";
import ReusableModal from "../reusable/modal/reusablemodal";
import { onSetID } from "../../store/actions/getterAction";
import { onAuthStateChanged } from "firebase/auth";
import { Input } from "../reusable/input";

const UserDetails = () => {
  const [vendcode, setvendcode] = useState(false);
  const [user, setUser] = useState();
  const [uid, setuid] = useState("");
  const [data, setdata] = useState();
  const [stat, getStat] = useState();
  const [docs, setDocs] = useState();
  const [load, setLoad] = useState(false);
  const [query, setquery] = useState("");
  const [queryList, setquerylist] = useState([]);
  const [switchtable, setswitchtable] = useState(false);
  const [cnfm, setcnfm] = useState(false);
  const [recall, setrecall] = useState(false);
  const [approval, setApproval] = useState(false);
  const [sendflag, setsendflag] = useState(false);
  const [queryflag, setqueryflag] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const queryTitle = useRef();
  const queryRef = useRef("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setUser(_tmp);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (useruid == "") {
      navigate("/dashboard");
    }
    setuid(useruid);
  }, [useruid]);

  useEffect(() => {
    if (uid) {
      onSnapshot(doc(db, "users", uid), (doc) => {
        const _tmp = doc.data();
        if (_tmp?.system_code) {
          setvendcode(true)
        }
        setdata(_tmp);
        setquerylist(_tmp?.queries?.general);
        getStat(_tmp?.forms);
      });
      onSnapshot(doc(db, "documents", uid), (doc) => {
        const _tmp = doc.data();
        setDocs(_tmp);
      });
    }
  }, [uid, recall == true]);

  createTheme("solarized", {
    text: {
      primary: "#FFFFFF",
    },
    background: {
      default: "rgba(255, 255, 255, 0.07)",
    },
  });

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        backgroundColor: "rgba(255,255,255,0.8)",
        backdropFilter: "blur(15px)",
        color: "black",
        borderRadiusTopLeft: "10px",
      },
    },
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.id,
      sortable: true,
      width: "105px",
    },
    {
      name: "Form Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getStatus(row.status),
      sortable: true,
    },
    {
      name: "View",
      selector: (row) =>
        row.isApplicable == "yes" ? (
          "NA"
        ) : (
          <p onClick={() => handleRedirect(row.name)}>
            <i className="fa-solid fa-eye fa-xl hover:text-yellow-400" />
          </p>
        ),
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const traineeColumns = [
    {
      name: "Sr. No.",
      selector: (row) => row.id,
      sortable: true,
      width: "105px",
    },
    {
      name: "Form Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getStatus(row.status),
      sortable: true,
    },
    {
      name: "View",
      selector: (row) =>
        row.isApplicable == "yes" ? (
          "NA"
        ) : (
          <p onClick={() => handleRedirect(row.name, "trainee")}>
            <i className="fa-solid fa-eye fa-xl hover:text-yellow-400" />
          </p>
        ),
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const documentcolumns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "105px",
    },
    {
      name: "Document Name",
      selector: (row) => row.name,
      sortable: true,
      width: "600px",
    },
    {
      name: "View/Download",
      selector: (row) =>
        row.view && row.view !== undefined ? (
          <a href={row.view} target="_blank">
            <i className="fa-solid fa-eye fa-xl hover:text-black" />
          </a>
        ) : (
          "NA"
        ),
      // width: '80px',
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const vendorColumns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      width: "105px",
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Vendor User ID",
      selector: (row) => row.userid,
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Vendor Name",
      selector: (row) => row.name,
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      name: "Vendor Email",
      selector: (row) => row.email,
      sortable: true,
      style: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleRedirect = (type, usertype) => {
    if (usertype == "trainee") {
      if (type == "Non-applicability of GST") navigate(`/nonapplicability`);
      if (type == "Trainee Data Form") navigate(`/mt-trainee-form`);
      if (type == "Trainee Bank Details") navigate(`/bankdetails`);
    } else {
      if (type == "Non-applicability of GST") navigate(`/nonapplicability`);
      if (type == "Vendor Data Form") navigate(`/vendordata`);
      if (type == "Vendor E-Invoicing") navigate(`/einvoicing`);
      if (type == "Vendor Bank Details") navigate(`/bankdetails`);
      if (type == "Master Service Agreement") navigate(`/msa`);
    }
  };

  const rows = [
    {
      id: 1,
      name: "Non-applicability of GST",
      status: stat?.nonapplicability,
      isApplicable: data?.isGSTApplicable,
    },
    {
      id: 2,
      name: "Vendor Data Form",
      status: stat?.vendorform,
      isApplicable: "",
    },
    {
      id: 3,
      name: "Vendor E-Invoicing",
      status: stat?.einvoicing,
      isApplicable: data?.isEinvoicingApplicable,
    },
    {
      id: 4,
      name: "Vendor Bank Details",
      status: stat?.bankdetails,
      isApplicable: "",
    },
    {
      id: 5,
      name: "Master Service Agreement",
      status: stat?.msa,
      isApplicable: data?.isMT == "yes" ? "" : "yes",
    },
  ];

  const traineeRows = [
    {
      id: 1,
      name: "Non-applicability of GST",
      status: stat?.nonapplicability,
      isApplicable: data?.isGSTApplicable,
    },
    {
      id: 2,
      name: "Trainee Data Form",
      status: stat?.vendorform,
      isApplicable: "",
    },
    {
      id: 3,
      name: "Trainee Bank Details",
      status: stat?.bankdetails,
      isApplicable: "",
    },
  ];

  const documentrows = [
    {
      name: "Company/LLP Registration Certificate/Aadhar Card",
      view: docs?.CompanyCertificate,
    },
    {
      name: "VAT Registration Certificate",
      view: docs?.VATCertificate,
    },
    {
      name: "GST Registration Certificate",
      view: docs?.GSTCertificate,
    },
    {
      name: "PAN Card/TAN Certificate",
      view: docs?.PANTANCard,
    },
    {
      name: "Tax Residency Certificate (TRC)",
      view: docs?.TRCertificate,
    },
    {
      name: "Tax Exemption / Lower Deduction of Tax Certificate (if applicable)",
      view: docs?.TAXExemption,
    },
    {
      name: "MSME Reg. certificate if registered",
      view: docs?.MSMECertificate,
    },
    {
      name: "Vendor Master Service Agreement (if applicable)",
      view: docs?.VendorMasterService,
    },
    {
      name: "EPD Arrangement letter (Only for MSME Vendor)",
      view: docs?.EPDLetter,
    },
    {
      name: "Authorisation By Client (if vendor is referred by Client)",
      view: docs?.AuthByCLient,
    },
  ];

  const handleChangeStatus = async () => {
    queryList &&
      queryList.length > 0 &&
      queryList.map((query, index) => {
        if (query.title == queryTitle.current) {
          queryList[index].status = 2;
          return;
        }
      });
    await updateDocument("users", useruid, { "queries.general": queryList });
    showToast("success", "Status changed Successfully!");
    setrecall(false);
  };

  useEffect(() => {
    if (recall && queryList && queryList.length > 0) {
      handleChangeStatus();
    }
  }, [recall === true]);

  useEffect(() => {
    if (stat?.status === 2) {
      setApproval(true);
    } else {
      setApproval(false);
    }
  }, [stat]);

  useEffect(() => {
    if (
      stat?.status == 1 &&
      stat?.bankdetails == 2 &&
      stat?.vendorform == 2 &&
      (stat?.einvoicing == 2 || stat?.nonapplicability == 2 || stat?.msa == 2)
    ) {
      handleStatusUpdate();
    }
  }, [stat]);

  const handleStatusUpdate = async () => {
    await updateDocument("users", useruid, { "forms.status": 2 });
  };

  const handlQuery = async () => {
    setLoad(true);
    const currentDate = Date.now();
    const list = {
      title: query,
      timestamp: currentDate,
      status: 0,
    };
    await updateDocument("users", useruid, {
      "queries.general": arrayUnion(list),
    });
    if (query === "") {
      showToast("success", "Query updated Successfully");
      setLoad(false);
      return;
    }
    setLoad(false);
    queryRef.current.value = "";
  };

  const flagRef = useRef(false);

  const handleApprove = async () => {
    setsendflag(true);
    flagRef.current = true;
    if (flagRef.current) {
      try {
        await updateDocument("users", useruid, {
          forms: { ...data.forms, status: 3 },
          approvalMail: true,
        });
        sendMail();
        setsendflag(false);
      } catch (e) {
        console.log(e);
        setsendflag(false);
      }
    }
  };

  const sendMail = async () => {
    try {
      let _tmpquery = true;
      if (data?.queries && (data?.queries != null || data?.queries != undefined || data?.queries != {})) {
        var count = 0;
        for (const key in data?.queries) {
          if (data?.queries.hasOwnProperty(key)) {
            const items = data?.queries[key];
            items.forEach(item => {
              if (item.status < 2) {
                count += 1;
              }
            });
          }
        }

        if (count > 0) {
          _tmpquery = false;
        }
      }
      const payload = {
        name: data?.name,
        location: data?.address,
        pan: data?.pancard,
        approverMail: "raghuraaman.janakiraman@omnicommediagroup.com",
        email: data?.email,
        uid: data?.uid,
        vendorType:
          data?.vendortype != "NA" ? data?.vendortype : data?.usertype,
        introducerName: data?.fpoc,
        introducerEmail: data?.fpocno,
        gstFillingStatus: data?.gst?.status,
        remarks: {
          gstteam: isEmpty(data?.gst?.remark),
          vendorteam: _tmpquery
            ? "All Okay, once approval received we will create the code in system"
            : "Queries Pending",
        },
      };

      console.log(payload);
      await axios
        .post(BASE_URL + "/api/mail/approvemail", payload)
        .then((res) => {
          if (res?.data) {
            showToast("success", "Send to Approver");
            setLoad(false);
            setsendflag(false);
          }
        })
        .catch((err) => {
          showToast("warning", err);
          setLoad(false);
          setsendflag(false);
        });
    } catch (e) {
      showToast("warning", e);
    }
  };

  const sendQuery = async () => {
    setqueryflag(true);

    const qdata = data?.queries;
    const filteredData = {};

    for (const key in qdata) {
      if (qdata[key].length > 0 && qdata[key].every(item => item.status !== 2)) {
        filteredData[key] = qdata[key];
      }
    }

    const payload = {
      name: data?.name,
      email: data?.email,
      queries: filteredData,
      introducerEmail: data?.fpocno,
      vendorType: data?.usertype,
    };
    try {
      axios
        .post(BASE_URL + "/api/mail/sendquery", payload)
        .then((res) => {
          if (res?.data) {
            showToast("success", "Query sent to vendor");
            setqueryflag(false);
          }
        })
        .catch((err) => {
          showToast("warning", err);
          setqueryflag(false);
        });
    } catch (e) {
      showToast("warning", e);
      setqueryflag(false);
    }
  };

  const handleUser = (event) => {
    dispatch(onSetID(event.uid));
    navigate(`/user`);
  };

  return (
    <>
      <div className="mx-5 py-4 w-full h-full rounded-lg border-gray-800 shadow-md flex">
        {/* Left Pan */}
        <div className="w-9/12 m-0 p-10 pt-0 ps-0 align-center rounded-lg border-gray-800">
          {/* Breadcrum */}
          <nav
            className="flex justify-between px-4 py-2.5 text-white shadow-none glass"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-3 text-truncate">
              <li className="inline-flex items-center">
                <svg
                  className="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                <Link
                  to="/dashboard"
                  className="inline-flex items-center text-sm font-medium text-gray-100 hover:text-gray-300"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p className="cursor-pointer ml-1 text-sm font-medium hover:text-blue-400 text-blue-400 md:ml-2 text-truncate">
                    {data?.name}
                  </p>
                </div>
              </li>
            </ol>
            {data?.logs &&
              <button
                className="ms-2 text-gray-50 px-3 bg-blue-600 rounded-full text-sm"
                onClick={() => {
                  setShowLogs(true);
                }}
                title="View logs"
              >
                <i className="fa-solid fa-check" /> View Logs
              </button>}
          </nav>
          {/* End of breadcrum */}

          <div className="glass px-4 py-3 shadow-none w-full my-3 text-white">
            <div className="text-white text-center h5 pb-3 border-bottom border-secondary break-all">
              General Information
            </div>

            <div className="d-flex flex-wrap">
              <div
                className="col-4 p-2 mb-2 text-wrap"
                style={{ wordBreak: "break-word" }}
              >
                <label className="text-gray-300 h6 mb-1">Name</label>
                <p className="text-base">{isEmpty(data?.name)}</p>
              </div>
              <div
                className="col-4 p-2 mb-2 text-wrap"
                style={{ wordBreak: "break-word" }}
              >
                <label className="text-gray-300 h6 mb-1">Email</label>
                <p className="text-base">{isEmpty(data?.email)}</p>
              </div>
              <div
                className="col-4 p-2 mb-2 text-wrap"
                style={{ wordBreak: "break-word" }}
              >
                <label className="text-gray-300 h6 mb-1">User Type</label>
                <p className="text-base">
                  {isEmpty(data?.usertype?.toUpperCase())}
                </p>
              </div>
              <div
                className="col-4 p-2 mb-2 text-wrap"
                style={{ wordBreak: "break-word" }}
              >
                <label className="text-gray-300 h6 mb-1">Designation</label>
                <p className="text-base">{isEmpty(data?.designation)}</p>
              </div>
              <div
                className="col-4 p-2 mb-2 text-wrap"
                style={{ wordBreak: "break-word" }}
              >
                <label className="text-gray-300 h6 mb-1">Contact Number</label>
                <p className="text-base">{isEmpty(data?.phone)}</p>
              </div>
              {data?.usertype == "introducer" && (
                <div
                  className="col-4 p-2 mb-2 text-wrap"
                  style={{ wordBreak: "break-word" }}
                >
                  <label className="text-gray-300 h6 mb-1">
                    Invited Vendor Counts
                  </label>
                  <p className="text-base">
                    {isEmpty(data?.invitedVendors?.length)}
                  </p>
                </div>
              )}
              {data?.usertype === "vendor" || data?.usertype === "trainee" ? (
                <>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">Pan Card</label>
                    <p className="text-base">{isEmpty(data?.pancard)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">Aadhar Card</label>
                    <p className="text-base">{isEmpty(data?.aadharcard)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">
                      GSTIN Number
                    </label>
                    <p className="text-base">{isEmpty(data?.GSTIN)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">
                      Introducer Name
                    </label>
                    <p className="text-base">{isEmpty(data?.fpoc)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">
                      Introducer Email
                    </label>
                    <p
                      className="text-base text-truncate"
                      title={isEmpty(data?.fpocno)}
                    >
                      {isEmpty(data?.fpocno)}
                    </p>
                  </div>
                  <div
                    className="col-8 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">Address</label>
                    <p className="text-base">{isEmpty(data?.address)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">
                      GST Filing Status
                    </label>
                    <p className="text-base text-uppercase">
                      {isEmpty(data?.gst?.status)}
                    </p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">GST Remark</label>
                    <p className="text-base">{isEmpty(data?.gst?.remark)}</p>
                  </div>
                  <div
                    className="col-4 p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">
                      Vendor Team Remark
                    </label>
                    <p className="text-base">{isEmpty(data?.vendorRemark)}</p>
                  </div>
                  {(data?.forms?.status == 4 || data?.forms?.status == 5) && data?.forms?.approved ?
                    // {true ?
                    <>
                      <div
                        className="col-4 p-2 mb-2 text-wrap"
                        style={{ wordBreak: "break-word" }}
                      >
                        <label className="text-gray-300 h6 mb-1">{vendcode ? "Vendor Code" : "Update Vendor Code"}</label>
                        {vendcode ?
                          <p className="text-base">{isEmpty(data?.system_code)}</p>
                          :
                          <div className="d-flex align-items-center">
                            <Input
                              inputName="system_code"
                              onChange={(e) => setdata({
                                ...data,
                                system_code: e.target.value
                              })}
                              value={data?.system_code}
                            />
                            <button
                              className="ms-2 text-gray-50 p-2 -mt-1 bg-blue-600 rounded-full text-sm"
                              onClick={async () => {
                                console.log(data?.system_code);
                                await updateDocument("users", useruid, {
                                  system_code: data?.system_code
                                });
                              }}
                              title="Submit the Vendor Code"
                            >
                              <i className="fa-solid fa-check" />
                            </button>
                          </div>
                        }
                      </div>
                      <div
                        className="col-4 p-2 mb-2 text-wrap"
                        style={{ wordBreak: "break-word" }}
                      >
                        <label className="text-gray-300 h6 mb-1">
                          Approved On
                        </label>
                        <p className="text-base">{epochToTimestamp(data?.approvedOn?.seconds || data?.approvedAsExceptionOn?.seconds || data?.denyedOn?.seconds, false)}</p>
                      </div>
                    </>
                    : ""
                  }
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {data?.usertype === "vendor" || data?.usertype === "trainee" ? (
            <div className="glass px-4 py-3 shadow-none w-full my-3 text-white">
              <div className="text-white text-center h5 pb-3 border-bottom border-secondary break-all">
                Vendor Forms
              </div>

              <div className="d-flex flex-wrap">
                {data?.vendorTypes && data?.vendorTypes.length > 0 ? (
                  <div
                    className="col-6 flex flex-col p-2 mb-2 text-wrap"
                    style={{ wordBreak: "break-word" }}
                  >
                    <label className="text-gray-300 h6 mb-1">Vendor Type</label>
                    <div>
                      {data?.vendorTypes && data?.vendorTypes.length > 0
                        ? data?.vendorTypes?.map((type, index) => {
                          return type.isChecked ? (
                            <span className="m-0 me-1 inline-flex items-center justify-center px-2 py-1 text-sm font-light leading-none text-indigo-100 bg-indigo-700 rounded">
                              {type.name}{" "}
                            </span>
                          ) : null;
                        })
                        : "NA"}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {user?.usertype != "auditor" && data?.queries ? (
                  <div className="col-6 d-flex flex-column">
                    <label className="text-gray-300 h6 mb-2">
                      Send Query via Mail
                    </label>
                    {queryflag ? (
                      <SmallSpinner className="" loader={queryflag} />
                    ) : (
                      <button
                        className="bg-blue-700 hover:bg-blue-800 text-sm w-1/3 text-gray-50 p-1.5 rounded-lg"
                        onClick={() => sendQuery()}
                      >
                        Send Mail
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-12 d-flex px-2 my-3 align-items-center justify-content-start">
                  <span
                    onClick={(e) => setswitchtable(!switchtable)}
                    className="cursor-pointer me-3 text-sm font-medium text-gray-50"
                  >
                    Forms
                  </span>
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={switchtable}
                      onChange={(e) => setswitchtable(e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-50">
                      Documents
                    </span>
                  </label>
                </div>
                {switchtable ? (
                  <DataTable
                    columns={documentcolumns}
                    data={documentrows}
                    responsive
                    highlightOnHover
                    fixedHeader
                    theme="solarized"
                    customStyles={customStyles}
                    fixedHeaderScrollHeight="400px"
                  />
                ) : data?.usertype === "trainee" ? (
                  <DataTable
                    columns={traineeColumns}
                    data={traineeRows}
                    responsive
                    highlightOnHover
                    fixedHeader
                    theme="solarized"
                    customStyles={customStyles}
                    fixedHeaderScrollHeight="500px"
                  />
                ) : (
                  <DataTable
                    columns={columns}
                    data={rows}
                    responsive
                    highlightOnHover
                    fixedHeader
                    theme="solarized"
                    customStyles={customStyles}
                    fixedHeaderScrollHeight="500px"
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.usertype === "vendor" || data?.usertype === "trainee" ? (
            <div>
              <div
                className="mb-2 text-wrap "
                style={{ wordBreak: "break-word" }}
              >
                {sendflag ? (
                  <div className="d-flex justify-center align-middle">
                    <SmallSpinner loader={sendflag} />
                  </div>
                ) : (
                  user?.usertype != "auditor" && (
                    <button
                      className="rounded-lg bg-blue-700 hover:bg-blue-800 text-white w-100 p-2 mt-2 disabled:bg-blue-900"
                      disabled={data?.approvalMail}
                      onClick={handleApprove}
                    >
                      Send for Approval
                    </button>
                  )
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.usertype == "introducer" &&
            data &&
            data?.invitedVendors?.length > 0 ? (
            <div className="glass px-4 py-3 shadow-none w-full my-3 text-white">
              <div className="text-white text-center h5 pb-3 border-bottom border-secondary break-all">
                Invited Vendor List
              </div>
              <div className="d-flex flex-wrap">
                <DataTable
                  columns={vendorColumns}
                  data={data?.invitedVendors}
                  responsive
                  highlightOnHover
                  fixedHeader
                  theme="solarized"
                  customStyles={customStyles}
                  fixedHeaderScrollHeight="500px"
                  onRowClicked={(row) => handleUser(row)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Right Sidebar */}
        <div className="fixed-panel w-3/12 p-10 pt-0 pe-0 align-center rounded-lg border-gray-800">
          <div className="glass px-4 py-2.5 shadow-none text-center text-white">
            <p className="inline-flex items-center space-x-1 md:space-x-3 text-truncate">
              <label className="text-gray-300 h6 mb-0">
                User ID : {isEmpty(data?.userid)}
              </label>
            </p>
          </div>
          {user?.usertype != "auditor" && (
            <div className="glass px-4 py-3 shadow-none my-3 text-white">
              <div className="text-white text-center h5 pb-2 border-bottom border-secondary break-all">
                General Information
              </div>
              <textarea
                ref={queryRef}
                className="glass w-100 shadow-none"
                cols={10}
                rows={5}
                onChange={(e) => setquery(e.target.value)}
                type="text"
              />
              {load ? (
                <SmallSpinner className="m-auto my-3" loader={load} />
              ) : (
                <button
                  className="rounded-lg bg-blue-800 text-white w-full p-2 mt-2 disabled:bg-blue-900"
                  onClick={handlQuery}
                  disabled={queryRef.current.value == ""}
                >
                  Send Query
                </button>
              )}
            </div>
          )}
          {queryList && queryList.length > 0 && (
            <div className="glass  px-4 py-3 shadow-none my-3 text-white">
              <div className="text-white text-center h5 pb-2 border-bottom border-secondary break-all">
                Previous Queries
              </div>
              <div className="max-h-56 overflow-auto flex flex-col-reverse">
                {queryList &&
                  queryList.map((q) => (
                    <>
                      <div
                        onClick={() => {
                          if (user?.usertype != "auditor") {
                            queryTitle.current = q.title;
                            setcnfm(true);
                          }
                        }}
                        title={
                          user?.usertype == "auditor"
                            ? q.title
                            : "Click to change status"
                        }
                        className="cursor-pointer text-gray-200 rounded-md mb-2 bg-slate-600 px-3 text-sm py-1 hover:text-gray-100 hover:bg-slate-500 "
                      >
                        <p className="break-all">{q.title}</p>
                        <p className="text-end">
                          <small className="w-full text-xs text-gray-300">
                            {epochToTimestamp(q.timestamp)}
                            <i className={getStatusIcon(q.status)} />
                          </small>
                        </p>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          )}
        </div>
        {/* end right side */}
      </div>
      {cnfm ? (
        <ReusableModal
          show={cnfm}
          recall={recall}
          setrecall={setrecall}
          onClose={() => setcnfm(false)}
          title={`Query: ${queryTitle?.current}`}
          subtitle="Change the status to Completed?"
          confirmButton="Yes"
          cancelButtom="No"
        />
      ) : (
        ""
      )}
      {showLogs && (
        <ReusableModal
          modalTitle={`Logs for ${data?.name}`}
          content={
            <>
              <div className="max-h-72 max-w-xl overflow-auto flex flex-col-reverse">
                {data && data?.logs && data?.logs.length > 0 ? data?.logs?.map((log) => (
                  <div title={log?.title}
                    className="text-gray-200 rounded-md mb-2 border border-slate-600 px-3 text-sm py-1"
                  >
                    <p className="break-all">{log?.title}</p>
                    <p className="text-end">
                      <small className="w-full text-xs text-gray-300">
                        {epochToTimestamp(log.timestamp, false, true)}
                      </small>
                    </p>
                  </div>
                )) : ""}
              </div>
            </>
          }
          show={showLogs}
          onClose={() => {
            setShowLogs(false);
          }}
        />
      )}
    </>
  );
};

export default UserDetails;
