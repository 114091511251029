import moment from "moment/moment";

export const formatDate = (formatIdentifier, inputdate) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let currentDate = new Date();
    let date = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let type = formatIdentifier;
    if (type == "") {
        type = "-"
    }
    if (type == "words") {
        return date + "th day of " + monthNames[month - 1] + ", " + year
    }

    if (inputdate && type == "words") {
        const _tmp = inputdate.split("-");
        return _tmp[2] + "th day of " + monthNames[_tmp[1] - 1] + ", " + _tmp[0];
    }
    if (inputdate && type == "-") {
        const _tmp = inputdate.split("-");
        return _tmp[0] + type + _tmp[1] + type + _tmp[2];
    }

    return year + type + month + type + date;
}

export const generateUniqueID = (id) => {
    const uid = id?.slice(-5).toUpperCase();
    const day = new Date();
    const month = day.getMonth() + 1;
    const year = day.getFullYear().toString().slice(-2);

    return uid != undefined && `O${year}${month}${uid}`;
}

export const truncateUID = (uid) => {
    return uid.slice(0, 4) + "..." + uid.slice(24, 28);
}


export const getInitials = (name) => {
    var initials = "";
    if (name == "" || name == undefined || name == null) {
        return "O";
    }
    const namearr = name.split(" ");
    if (namearr.length == 1) {
        initials = namearr[0][0];
    } else if (namearr.length > 1) {
        initials = namearr[0][0] + namearr[namearr.length - 1][0];
    }
    // for (var i = 0; i < namearr.length; i++) {
    // initials += namearr[i][0];
    // }
    return initials.toUpperCase();
}

export const isEmpty = (data) => {
    return data ? data : "NA";
}

export const epochToTimestamp = (epoch, now = true, logs = false) => {
    if (now) {
        return moment(epoch).fromNow();
    } else {
        if (logs) {
            return moment(epoch).format('llll');
        }
        return moment.unix(epoch).format('llll');
    }
}

export const getStatus = (status) => {
    if (status == 0) {
        return "Pending"
    }
    if (status == 1) {
        return "Submitted"
    }
    if (status == 2) {
        return "Validated"
    }
    if (status == 3) {
        return "Approved"
    }
    if (status == -1) {
        return "Queries"
    }
    if (status == undefined || status == "" || status == null) {
        return "Pending"
    }
}

export const getStatusIcon = (status) => {
    if (status == 0) {
        return "fa-regular fa-clock ms-1"
    }
    if (status == 1) {
        return "fa-solid fa-check text-yellow-400 fa-xl ms-1"
    }
    if (status == 2) {
        return "fa-solid fa-check text-green-400 fa-xl ms-1"
    }
}