import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../../firebase.config";
import GSTTable from "./gstTable";
import PartyDisclosure from "./partyDisclosure";
import { showToast } from "../../../utils/toast";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

const VendorDetails = () => {
  const [data, setdata] = useState();
  const [generalObj, setgeneralobj] = useState({});
  const [generalList, setgenerallist] = useState([]);
  const [accountsDetails, setaccountsdetails] = useState({});
  const [salesDetails, setsalesdetails] = useState({});
  const [makerDetails, setmakerdetails] = useState({});
  const [secOne, setSecOne] = useState([]);
  const [secFive, setSecFive] = useState([]);
  const [secSix, setSecSix] = useState([]);
  const [isEditable, setisEditable] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    sectionOne: {},
    sectionTwo: {},
    sectionThree: {},
    sectionFour: {},
    sectionFive: {},
    sectionSix: {},
  });
  const [user, setUser] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setUser(_tmp);
        });
      }
    });
  }, []);
  const pdfref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfref.current,
    onBeforeGetContent: () => {
      document.getElementById("printbtn").classList.add("d-none");
      document.getElementById("printDiv").classList.remove("glass");
      document.getElementById("collapseSectionOne").classList.add("show");
      document.getElementById("collapseSectionTwo").classList.add("show");
      document.getElementById("collapseSectionThree").classList.add("show");
      document.getElementById("collapseSectionFour").classList.add("show");
      document.getElementById("collapseSectionFive").classList.add("show");
      document.getElementById("collapseSectionSix").classList.add("show");
      // document.getElementById('disableDiv').classList.add('d-none');
    },
    onAfterPrint: () => {
      document.getElementById("printbtn").classList.remove("d-none");
      document.getElementById("printDiv").classList.add("glass");
      document.getElementById("collapseSectionOne").classList.remove("show");
      document.getElementById("collapseSectionTwo").classList.remove("show");
      document.getElementById("collapseSectionThree").classList.remove("show");
      document.getElementById("collapseSectionFour").classList.remove("show");
      document.getElementById("collapseSectionFive").classList.remove("show");
      document.getElementById("collapseSectionSix").classList.remove("show");
      // document.getElementById('disableDiv').classList.remove('d-none');
    },
  });

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  const [gstRows, setGSTData] = useState([
    {
      gstStateCode: "",
      gstNo: "",
      billingAddress: "",
      serviceName: "",
      sacCode: "",
      contactDetails: "",
    },
  ]);

  const [partyRows, setPartyRows] = useState([
    {
      vendorPartyName: "",
      vendorRole: "",
      vendorInterestedPartyName: "",
      detailsOfInterest: "",
    },
  ]);

  const scroll = (search) => {
    if (search.includes("One")) {
      window.scrollBy(0, 550);
    }
    if (search.includes("Two")) {
      window.scrollBy(0, 622);
    }
    if (search.includes("Three")) {
      window.scrollBy(0, 700);
    }
    if (search.includes("Four")) {
      window.scrollBy(0, 760);
    }
    if (search.includes("Five")) {
      window.scrollBy(0, 830);
    }
    if (search.includes("Six")) {
      window.scrollBy(0, 900);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const path = window.location.hash.replace("#", "");
      let accord = document.getElementById(path);
      const isCollapse = accord.classList.contains("collapse");
      if (isCollapse) {
        accord.classList.remove("collapse");
        accord.classList.add("collapsed");
        scroll(path);
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    const keyPersonal = {
      accountsDetails,
      salesDetails,
      makerDetails,
    };
    setSecOne({
      ...secOne,
      ...keyPersonal,
    });
  }, [accountsDetails, salesDetails, makerDetails]);

  // GST Table Functions
  const addGSTTableRows = () => {
    const rowsInput = {
      gstStateCode: "",
      gstNo: "",
      billingAddress: "",
      serviceName: "",
      contactDetails: "",
    };
    setGSTData([...gstRows, rowsInput]);
  };

  const deleteGSTTableRows = (index) => {
    const rows = [...gstRows];
    rows.splice(index, 1);
    setGSTData(rows);
  };

  const handleGSTChange = (index, e) => {
    const { name, value } = e.target;
    var rowsInput = [...gstRows];
    rowsInput[index][name] = value;
    setGSTData(rowsInput);
  };

  useEffect(() => {
    setSecOne({
      ...secOne,
      gstTable: gstRows,
    });
  }, [gstRows]);

  // Party Disclosure Functions

  const addPartyTableRows = () => {
    const rowsInput = {
      vendorPartyName: "",
      vendorRole: "",
      vendorInterestedPartyName: "",
      detailsOfInterest: "",
    };
    setPartyRows([...partyRows, rowsInput]);
  };

  const deletePartyTableRows = (index) => {
    const rows = [...partyRows];
    rows.splice(index, 1);
    setPartyRows(rows);
  };

  const handlePartyChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...partyRows];
    rowsInput[index][name] = value;
    setPartyRows(rowsInput);
  };

  useEffect(() => {
    setSecFive({
      ...secFive,
      partyTable: partyRows,
    });
  }, [partyRows]);

  // Form data save functions

  const handleSetState = (e, section) => {
    if (e.target.value) {
      if (section === 1) {
        setSecOne({
          ...secOne,
          [e.target.name]: e.target.value,
        });
      }
      if (section === 5) {
        setSecFive({
          ...secFive,
          [e.target.name]: e.target.value,
        });
      }
      if (section === 6) {
        setSecSix({
          ...secSix,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleSaveSection = (section) => {
    if (section === 1) {
      setVendorDetails({
        ...vendorDetails,
        sectionOne: secOne,
      });
      showToast("success", "Section I Saved Successfully");
    }
    if (section === 5) {
      setVendorDetails({
        ...vendorDetails,
        sectionFive: secFive,
      });
      showToast("success", "Section V Saved Successfully");
    }
    if (section === 6) {
      setVendorDetails({
        ...vendorDetails,
        sectionSix: secSix,
      });
      showToast("success", "Section VI Saved Successfully");
    }
  };

  const handleKeyPersonalData = (e) => {
    if (e.target.name.includes("accounts")) {
      setaccountsdetails({
        ...accountsDetails,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name.includes("sales")) {
      setsalesdetails({
        ...salesDetails,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name.includes("maker")) {
      setmakerdetails({
        ...makerDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGeneralQuestions = (e) => {
    setgeneralobj({
      ...generalObj,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (
      generalObj?.companyName != undefined &&
      generalObj?.refName != undefined &&
      generalObj?.refNumber != undefined &&
      typeof generalObj?.refNumber == "string" &&
      generalObj?.refNumber?.includes(".com")
    ) {
      setgenerallist([...generalList, generalObj]);
      setgeneralobj({});
    }
  }, [generalObj]);

  useEffect(() => {
    if (generalList.length > 0) {
      setSecOne({
        ...secOne,
        generalQuestions: generalList,
      });
    }
  }, [generalList]);

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (useruid) {
      if (data?.forms?.vendorform === 1) {
        await updateDoc(doc(db, "users", useruid), { "forms.vendorform": 2 });
        showToast("success", "Vendor Form Validated!");
      } else {
        showToast("warning", "Form is Incomplete!");
      }
    }
  };

  // to get the prefilled form

  useEffect(() => {
    if (useruid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        if (
          doc.data().vendorDetails &&
          doc.data().vendorDetails != {} &&
          doc.data().vendorDetails != undefined
        ) {
          setisEditable(true);
          const allData = doc.data()?.vendorDetails;
          setdata(doc.data());
          setVendorDetails(allData);
          console.log("sectionOne", allData?.sectionOne?.reference);
          setSecOne(allData?.sectionOne);
          setSecFive(allData?.sectionFive);
          setSecSix(allData?.sectionSix);
          setaccountsdetails(allData?.sectionOne?.accountsDetails);
          setsalesdetails(allData?.sectionOne?.salesDetails);
          setmakerdetails(allData?.sectionOne?.makerDetails);
          setGSTData(allData?.sectionOne?.gstTable);
          setPartyRows(allData?.sectionFive?.partyTable);
        }
      });
    }
  }, [useruid]);

  return (
    <>
      <div
        ref={pdfref}
        id="printDiv"
        className="px-4 mt-3 w-full rounded-lg border-gray-800 glass"
      >
        <h3 className="text-white p-4 text-center text-uppercase lg:text-3xl md:text-xl">
          Vendor Registration Form
        </h3>
        <div
          className="accordion accordion-flush text-white"
          id="accordionExample"
        >
          {/* Section One */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secOne">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionOne"
                aria-expanded="true"
                aria-controls="collapseSectionOne"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 1 - Vendor Information Details
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionOne"
              className="accordion-collapse collapse"
              aria-labelledby="secOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="my-3">
                  <div className="row mt-2 ">
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Name of the Vendor
                      </label>
                      <input
                        type="text"
                        name="nameOfVendor"
                        onChange={(e) => handleSetState(e, 1)}
                        value={data?.name}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Business Address
                      </label>
                      <input
                        type="text"
                        name="businessAddress"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.businessAddress}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 1)
                      </label>
                      <input
                        type="text"
                        name="addressL1"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL1}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 2)
                      </label>
                      <input
                        type="text"
                        name="addressL2"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL2}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Address (Line 3)
                      </label>
                      <input
                        type="text"
                        name="addressL3"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.addressL3}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.city}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Pin/Zip/Post Code
                      </label>
                      <input
                        type="text"
                        name="pincode"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.pincode}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        name="country"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.country}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Telephone number with area code:
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.telephone}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Billing Address (if different from Business Address)
                      </label>
                      <input
                        type="text"
                        name="billingAddress"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.billingAddress}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Company Website if any
                      </label>
                      <input
                        type="text"
                        name="companyWebsite"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.companyWebsite}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Vendor status:
                      </label>
                      <select
                        defaultValue=""
                        name="vendorStatus"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.vendorStatus}
                        disabled
                        className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        aria-label="Default select"
                      >
                        <option selected value="" disabled>
                          Select
                        </option>
                        <option value="Public Limited Company">
                          Public Limited Company
                        </option>
                        <option value="Private Limited Company">
                          Private Limited Company
                        </option>
                        <option value="Partnership Firm">
                          Partnership Firm
                        </option>
                        <option value="Sole Proprietor Firm">
                          Sole Proprietor Firm
                        </option>
                        <option value="Local Inter Company">
                          Local Inter Company
                        </option>
                        <option value="Foreign Inter Company">
                          Foreign Inter Company
                        </option>
                        <option value="Individual(Moderator/Freelancer)">
                          Individual(Moderator/Freelancer)
                        </option>
                        <option value="Hindu Undivided Family">
                          Hindu Undivided Family
                        </option>
                        <option value="Employee">Employee</option>
                        <option value="Association of Persons">
                          Association of Persons
                        </option>
                        <option value="Trust and Body of Individuals">
                          Trust and Body of Individuals
                        </option>
                      </select>
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Date of Incorporation
                      </label>
                      <input
                        type="date"
                        name="dateOfIncorporation"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.dateOfIncorporation}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Registration number /Aadhar Card number
                      </label>
                      <input
                        type="text"
                        name="registrationNumber"
                        onChange={(e) => handleSetState(e, 1)}
                        value={data?.aadharcard}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        No. of years in the Business
                      </label>
                      <input
                        type="text"
                        name="yearsInBusiness"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.yearsInBusiness}
                        disabled
                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="none"
                      />
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Details of reference (if any) who introduced
                      </label>
                      <select
                        defaultValue=""
                        name="reference"
                        onChange={(e) => handleSetState(e, 1)}
                        value={secOne?.reference ? secOne?.reference : "NA"}
                        disabled
                        className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        aria-label="Default select"
                      >
                        <option selected value="" disabled>
                          Select
                        </option>
                        <option value="NA">NA</option>
                        <option value="Client Reference">
                          Client Reference
                        </option>
                        <option value="Worked with this vendor in past">
                          Worked with this vendor in past
                        </option>
                        <option value="Referred  by Co-worker">
                          Referred by Co-worker
                        </option>
                        <option value="OMD mandatory">OMD mandatory</option>
                        <option value="Advisory">Advisory</option>
                      </select>
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Are you part of Omnicom Group ? If yes, specify the Code
                        if any
                      </label>
                      <div className="input-group mb-3 flex-nowrap">
                        <select
                          defaultValue=""
                          name="que1"
                          style={{ width: "80px" }}
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.que1}
                          disabled
                          className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          aria-label="Default select"
                        >
                          <option>Yes</option>
                          <option value="no">No</option>
                        </select>
                        <input
                          type="text"
                          name="ans1"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.ans1}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Are you a Omnicom Group Preferred Vendor ? If yes,
                        provide the necessary document & provide Hyperian
                        Company Code
                      </label>
                      <div className="input-group mb-3 flex-nowrap">
                        <select
                          defaultValue=""
                          name="que2"
                          style={{ width: "80px" }}
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.que2}
                          disabled
                          className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          aria-label="Default select"
                        >
                          <option>Yes</option>
                          <option value="no">No</option>
                        </select>
                        <input
                          type="text"
                          name="ans2"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.ans2}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                    <div className="col-4 mb-4">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Are you working with any other Omnicom Group entity in
                        India? If yes, provide the name of the entity with whom
                        you are registered.
                      </label>
                      <div className="input-group mb-3 flex-nowrap">
                        <select
                          defaultValue=""
                          name="que3"
                          style={{ width: "80px" }}
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.que3}
                          disabled
                          className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          aria-label="Default select"
                        >
                          <option>Yes</option>
                          <option value="no">No</option>
                        </select>
                        <input
                          type="text"
                          name="ans3"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.ans3}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <h3 className="text-white text-start text-uppercase text-lg ">
                      Details of Key Personnel
                    </h3>
                    <div className="mt-2 row">
                      <table className="table table-borderless glass text-white">
                        <thead>
                          <tr>
                            <th></th>
                            <th className="text-center">Name & Designation</th>
                            <th className="text-center">
                              Phone No./Mobile No.
                            </th>
                            <th className="text-center">Email Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-left">
                              Contact Person in Accounts / Finance Dept.
                            </td>
                            <td>
                              <input
                                type="text"
                                name="accountsNameDesignation"
                                value={accountsDetails?.accountsNameDesignation}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="accountsPhoneNumber"
                                value={accountsDetails?.accountsPhoneNumber}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="accountsEmail"
                                value={accountsDetails?.accountsEmail}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              Contact Person in Sales / Service
                            </td>
                            <td>
                              <input
                                type="text"
                                name="salesNameDesignation"
                                value={salesDetails?.salesNameDesignation}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="salesPhoneNumber"
                                value={salesDetails?.salesPhoneNumber}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="salesEmail"
                                value={salesDetails?.salesEmail}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left">
                              Any Other Decision Maker (give Designation)
                            </td>
                            <td>
                              <input
                                type="text"
                                name="makerNameDesignation"
                                value={makerDetails?.makerNameDesignation}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="makerPhoneNumber"
                                value={makerDetails?.makerPhoneNumber}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="makerEmail"
                                value={makerDetails?.makerEmail}
                                disabled
                                onChange={(e) => handleKeyPersonalData(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="my-3">
                    <h3 className="text-white text-start text-uppercase text-lg">
                      Statute Data
                    </h3>
                    <div className="mt-2 row">
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          PAN No. (provide a copy of the PAN Card)
                        </label>
                        <input
                          type="text"
                          name="panno"
                          onChange={(e) => handleSetState(e, 1)}
                          value={data?.pancard}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Tax Residency Certificate No. (applicable for Overseas
                          vendors only) - Attach TRC copy (Higher WHT is
                          applicable in absence of TRC)
                        </label>
                        <input
                          type="text"
                          name="taxResidencyNo"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.taxResidencyNo}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Withholding taxes will be deducted as per the
                          prevailling applicable rates basis the tax
                          declarations/certificates provided to us
                        </label>
                        <input
                          type="text"
                          name="withHoldingTaxes"
                          onChange={(e) => handleSetState(e, 1)}
                          value="Yes"
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          GST Classification
                        </label>
                        <select
                          defaultValue=""
                          name="gstClassification"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.gstClassification}
                          disabled
                          className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          aria-label="Default select"
                        >
                          <option selected value="" disabled>
                            Select
                          </option>
                          <option value="GST Registration vendor">
                            GST Registration vendor
                          </option>
                          <option value="Unregistration vendor">
                            Unregistration vendor
                          </option>
                          <option value="Foreign Vendor">Foreign Vendor</option>
                          <option value="Composition Vendor">
                            Composition Vendor
                          </option>
                          <option value="Related Vendor">Related Vendor</option>
                          <option value="Branch Vendor">Branch Vendor</option>
                          <option value="Exempt">Exempt</option>
                        </select>
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          E-Invoicing Applicable?
                        </label>
                        <input
                          type="text"
                          name="eInvoicing"
                          value={secOne?.eInvoicing}
                          disabled
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                    <div className="mt-2 row">
                      <table className="table align-middle text-white glass border-0">
                        <thead>
                          <tr>
                            <th className="px-4 font-thin">GST State Code</th>
                            <th className="px-4 font-thin">GST No.</th>
                            <th className="px-4 font-thin">
                              Billing address as per GSTN
                            </th>
                            <th className="px-4 font-thin">
                              Goods Name / Service Name
                            </th>
                            <th className="px-4 font-thin">
                              Vendor's State specific Tax Personnel Email id &
                              Contact Number (Key Contact for mismatch in GST
                              returns)
                            </th>
                            <th className="px-4 font-thin">
                              <button
                                className="btn btn-success"
                                onClick={addGSTTableRows}
                                disabled
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <GSTTable
                            gstRows={gstRows}
                            deleteGSTTableRows={deleteGSTTableRows}
                            handleGSTChange={handleGSTChange}
                            disabled
                          />
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2 glass">
                      <div className="row">
                        <div className="col-4 d-flex align-items-center">
                          <p className="text-justify p-4">
                            I / we declare as follows in relation to Micro,
                            Small and Medium Enterprises Development Act, 2006
                            (hereinafter referred as “the Act”)
                          </p>
                        </div>
                        <div className="col-8">
                          <table className="table align-middle text-white border-0">
                            <thead>
                              <tr>
                                <th className="px-4 font-thin">
                                  MSME Investment in Plant & Machinery &
                                  turnover
                                </th>
                                <th className="px-4 font-thin">
                                  Type of Enterprise
                                </th>
                                <th className="px-4 font-thin">
                                  Please select as applicable
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td></td>
                                <td className="px-4">
                                  We are not covered under the Act
                                </td>
                                <td className="px-4">
                                  <select
                                    onChange={(e) => handleSetState(e, 1)}
                                    defaultValue={"no"}
                                    value={secOne?.noType}
                                    disabled
                                    name="noType"
                                    className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    aria-label="Default select"
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td className="px-4">
                                  Micro- &lt; Rs.1 crore & &lt; Rs.5 crore
                                </td>
                                <td className="px-4">
                                  I / We are covered under the Act as Micro
                                  Enterprise
                                </td>
                                <td className="px-4">
                                  <select
                                    defaultValue=""
                                    onChange={(e) => handleSetState(e, 1)}
                                    value={
                                      secOne?.microType
                                        ? secOne?.microType
                                        : "notapplicable"
                                    }
                                    disabled
                                    name="microType"
                                    className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    aria-label="Default select"
                                  >
                                    <option value="applicable">
                                      Applicable
                                    </option>
                                    <option value="notapplicable">
                                      Not Applicable
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td className="px-4">
                                  Small- &lt; Rs.10 crore & &lt; Rs.50 crore
                                </td>
                                <td className="px-4">
                                  I / We are covered under the Act as Small
                                  Enterprise
                                </td>
                                <td className="px-4">
                                  <select
                                    defaultValue=""
                                    onChange={(e) => handleSetState(e, 1)}
                                    value={
                                      secOne?.smallType
                                        ? secOne?.smallType
                                        : "notapplicable"
                                    }
                                    disabled
                                    name="smallType"
                                    className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    aria-label="Default select"
                                  >
                                    <option value="applicable">
                                      Applicable
                                    </option>
                                    <option value="notapplicable">
                                      Not Applicable
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td className="px-4">
                                  Medium- &lt; Rs.50 crore & &lt; Rs.250 crore
                                </td>
                                <td className="px-4">
                                  I / We are covered under the Act as Medium
                                  Enterprise
                                </td>
                                <td className="px-4">
                                  <select
                                    defaultValue=""
                                    onChange={(e) => handleSetState(e, 1)}
                                    value={
                                      secOne?.mediumType
                                        ? secOne?.mediumType
                                        : "notapplicable"
                                    }
                                    disabled
                                    name="mediumType"
                                    className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 disabled:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    aria-label="Default select"
                                  >
                                    <option value="applicable">
                                      Applicable
                                    </option>
                                    <option value="notapplicable">
                                      Not Applicable
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <h3 className="text-white text-start text-uppercase text-lg">
                      General Questions
                    </h3>
                    <div className="mt-2 row">
                      <table className="table table-borderless border-0 shadow-none glass text-white">
                        <thead>
                          <tr>
                            <th></th>
                            <th className="text-center">Company Name</th>
                            <th className="text-center">
                              Reference (Contact Name)
                            </th>
                            <th className="text-center">
                              Reference (Contact No. / Email)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              rowSpan={5}
                              width="400px"
                              className="text-center align-middle"
                            >
                              Who are your top 5 Corporate Clients (in
                              descending order of business). Provide a reference
                              contact details for each client.
                            </td>
                            <td>
                              <input
                                type="text"
                                name="companyName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[0]?.companyName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[0]?.refName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refNumber"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[0]?.refNumber
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                name="companyName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[1]?.companyName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[1]?.refName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refNumber"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[1]?.refNumber
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                name="companyName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[2]?.companyName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[2]?.refName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refNumber"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[2]?.refNumber
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                name="companyName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[3]?.companyName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[3]?.refName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refNumber"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[3]?.refNumber
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                name="companyName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[4]?.companyName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refName"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[4]?.refName
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="refNumber"
                                disabled
                                value={
                                  secOne?.generalQuestions &&
                                  secOne?.generalQuestions[4]?.refNumber
                                }
                                onChange={(e) => handleGeneralQuestions(e)}
                                className=" text-white shadow-none glass focus:bg-transparent"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2 row">
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Operating Locations - Countries / cities
                        </label>
                        <input
                          type="text"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.operatingLocations}
                          disabled
                          name="operatingLocations"
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Provide a brief on the Quality control steps taken in
                          your operations in relation to your services to
                          (Entity's Name to be added here)
                        </label>
                        <input
                          type="text"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.qualityControlOperation}
                          disabled
                          name="qualityControlOperation"
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          Provide a brief on the Quality control steps taken by
                          your vendor in relation to the services to (Entity's
                          Name to be added here)
                        </label>
                        <input
                          type="text"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.qualityControlVendor}
                          disabled
                          name="qualityControlVendor"
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                      <div className="col-4 mb-4">
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-gray-300"
                        >
                          How long do you retain the information submitted by
                          the Company{" "}
                        </label>
                        <input
                          type="text"
                          onChange={(e) => handleSetState(e, 1)}
                          value={secOne?.retainInformation}
                          disabled
                          name="retainInformation"
                          className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          autoComplete="none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!isEditable && (
                  <div className="my-3 d-flex w-48 ml-auto">
                    <button
                      className="btn btn-primary rounded-lg w-100"
                      onClick={() => handleSaveSection(1)}
                    >
                      Save Section I
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Section Two */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secTwo">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionTwo"
                aria-expanded="true"
                aria-controls="collapseSectionTwo"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 2 - Vendor Bank Information
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionTwo"
              className="accordion-collapse collapse"
              aria-labelledby="secTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <p className="font-light text-justify">
                  <strong>
                    <i className="fa-solid fa-circle-info" />
                  </strong>
                  Click here to{" "}
                  <Link to="/bankdetails" className="text-primary underline">
                    view
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Section Three */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secThree">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionThree"
                aria-expanded="true"
                aria-controls="collapseSectionThree"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 3 - Omnicom Group Code of business Conduct for Vendors
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionThree"
              className="accordion-collapse collapse"
              aria-labelledby="secThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="my-3">
                  <p className="font-light text-justify">
                    Policy with respect to Anti-Bribary/ Foreign Corrupt
                    Practices Act/Conflict of Interest/non-compete clause etc
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Section Four */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secFour">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionFour"
                aria-expanded="true"
                aria-controls="collapseSectionFour"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 4 - Document Checklist
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionFour"
              className="accordion-collapse collapse"
              aria-labelledby="secFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <p className="font-light text-justify">
                  <strong>
                    <i className="fa-solid fa-circle-info" />
                  </strong>
                  Click here to{" "}
                  <Link to="/user" className="text-primary underline">
                    view
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Section Five */}
          <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secFive">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionFive"
                aria-expanded="true"
                aria-controls="collapseSectionFive"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 5 - Vendor Related Party Disclosure
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionFive"
              className="accordion-collapse collapse"
              aria-labelledby="secFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="scenario1"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Vendor Party has control or joint control (including
                        direct or indirect ownership of a majority of shares or
                        voting rights) over a Omnicom Group Company
                      </label>
                      <input
                        type="radio"
                        name="scenario1"
                        value="yes"
                        checked={secFive?.scenario1 == "yes"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        Yes
                      </label>
                      <input
                        type="radio"
                        name="scenario1"
                        value="no"
                        checked={secFive?.scenario1 == "no"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        No
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="scenario2"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Vendor Party can exercise significant influence over
                        procurement, client, financial and/or reporting
                        decisions of a Omnicom Group Company
                      </label>

                      <input
                        type="radio"
                        name="scenario2"
                        value="yes"
                        checked={secFive?.scenario2 == "yes"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        Yes
                      </label>
                      <input
                        type="radio"
                        name="scenario2"
                        value="no"
                        checked={secFive?.scenario2 == "no"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        No
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="scenario3"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Vendor Party is a member of the key management personnel
                        (including directors) of a Omnicom Group Company
                      </label>

                      <input
                        type="radio"
                        name="scenario3"
                        value="yes"
                        checked={secFive?.scenario3 == "yes"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        Yes
                      </label>
                      <input
                        type="radio"
                        name="scenario3"
                        value="no"
                        checked={secFive?.scenario3 == "no"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        No
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label
                        htmlFor="scenario4"
                        className="block mb-2 text-sm font-medium text-gray-300"
                      >
                        Omnicom Group Company [Name] employees with influence in
                        or control over the Vendor party
                      </label>

                      <input
                        type="radio"
                        name="scenario4"
                        value="yes"
                        checked={secFive?.scenario4 == "yes"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        Yes
                      </label>
                      <input
                        type="radio"
                        name="scenario4"
                        value="no"
                        checked={secFive?.scenario4 == "no"}
                        disabled
                        onChange={(e) => handleSetState(e, 5)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        style={{ marginLeft: "5px", marginRight: "10px" }}
                        className="ml-5 text-sm font-medium text-gray-300"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table align-middle text-white glass shadow-none border-0">
                      <thead>
                        <tr>
                          <th className="px-4 font-thin">Sr. No.</th>
                          <th className="px-4 font-thin">
                            Full Name of Vendor Party
                          </th>
                          <th className="px-4 font-thin">
                            Role at/relationship with Vendor
                          </th>
                          <th className="px-4 font-thin">
                            Full name of interested party at Omnicom Group
                            Company
                          </th>
                          <th className="px-4 font-thin">
                            Nature/details of interest
                          </th>
                          <th className="px-4 font-thin">
                            <button
                              className="btn btn-success"
                              onClick={addPartyTableRows}
                              disabled
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <PartyDisclosure
                          partyRows={partyRows}
                          deletePartyTableRows={deletePartyTableRows}
                          handlePartyChange={handlePartyChange}
                          disabled
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section Six */}
          <div className="accordion-item mt-2 mb-3 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secSix">
              <button
                className="accordion-button bg-transparent collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSectionSix"
                aria-expanded="true"
                aria-controls="collapseSectionSix"
              >
                <h3 className="text-white text-start text-uppercase text-lg ">
                  Section 6 - No RO No Work Policy
                </h3>
              </button>
            </h2>
            <div
              id="collapseSectionSix"
              className="accordion-collapse collapse"
              aria-labelledby="secSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-white">
                <div className="row">
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Name of Service Provider
                    </label>
                    <input
                      type="text"
                      name="serviceProvider"
                      value={secSix?.serviceProvider}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Name of Signatory
                    </label>
                    <input
                      type="text"
                      name="signatoryName"
                      value={data?.name}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Position of Signatory
                    </label>
                    <input
                      type="text"
                      name="positionOfSignatory"
                      value={data?.designation}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Contact Number
                    </label>
                    <input
                      type="text"
                      name="signatoryContactNumber"
                      value={data?.phone}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Email ID
                    </label>
                    <input
                      type="text"
                      name="signatoryEmail"
                      value={data?.email}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Place
                    </label>
                    <input
                      type="text"
                      name="place"
                      value={secSix?.place}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label
                      htmlFor="date"
                      className="block mb-2 text-sm font-medium text-gray-300"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      name="signatoryDate"
                      value={secSix?.signatoryDate}
                      disabled
                      onChange={(e) => handleSetState(e, 6)}
                      className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      autoComplete="none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user?.usertype != "auditor" ? (
            <>
              {data?.forms?.vendorform === 1 && (
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="rounded-lg bg-blue-800 text-white w-full my-3 p-2 disabled:bg-blue-900"
                  disabled={data?.forms?.vendorform >= 2}
                >
                  Validate
                </button>
              )}
              {data?.forms?.vendorform >= 2 && (
                <button
                  id="printbtn"
                  onClick={() => handlePrint()}
                  className="rounded-lg bg-blue-800 text-white w-full my-3 p-2 disabled:bg-blue-900"
                >
                  Print
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
