import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { auth, db } from "../../../firebase.config";
import { showToast } from "../../../utils/toast";

const ExportEInvoicing = () => {
  const [uid, setuid] = useState("");
  const [name, setname] = useState("");
  const [sign, setsign] = useState("");
  const [user, setuser] = useState();
  const [data, setdata] = useState();
  const navigate = useNavigate();

  const printDivRef = useRef(null);

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  useEffect(() => {
    if (useruid == "") {
      navigate("/dashboard");
    }
    setuid(useruid);
  }, [useruid]);

  // const handlePrint = () => {
  //     document.getElementById('printbtn').style.display = "none";
  //     let printContents = document.getElementById('printableDIV').innerHTML;
  //     let originalContents = document.body.innerHTML;
  //     document.body.innerHTML = printContents;
  //     window.print();
  //     document.body.innerHTML = originalContents;
  //     document.getElementById('printbtn').style.display = "block";
  // }

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setuser(_tmp);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (useruid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        const _tmp = doc.data();
        console.log(_tmp);
        setdata(_tmp);
      });
      onSnapshot(doc(db, "documents", useruid), (doc) => {
        const _tmp = doc.data();
        setsign(_tmp?.signImage);
      });
    }
  }, []);

  const ComponentPrint = () => {
    return (
      <div
        ref={printDivRef}
        id="printableDIV"
        style={{ height: "calc(100vh - 145px)", overflow: "auto" }}
        className="mt-3 scroll-hide w-full rounded-lg  bg-white text-black"
      >
        <div className="text-justify overflow-y-auto p-20 pt-14">
          {/* <button id="printbtn" className='rounded-lg bg-blue-800 text-white w-full p-2 my-3' onClick={handlePrint}>Print</button> */}
          <p className="text-center">
            <b>
              <u>To whomsoever it may concern</u>
            </b>
          </p>
          <br />
          <p>
            We <b>Mr. {data?.name || "Your Name"}</b> having PAN{" "}
            <b>{data?.pancard || "Your PAN Card"}</b> hereby undertake that our
            Aggregate Turnover (as per Section 2(6) of Central Goods and
            Services Tax Act, 2017) for last Financial Year does not exceed the
            prescribed threshold for generation a Unique Invoice Registration
            Number (IRN) and QR code as per the provisions of Central Goods and
            Services Tax Act, 2017 and rules thereunder (“GST Law”).
          </p>
          <br />
          <p>
            We hereby also undertake that if the aggregate turnover of mine,{" "}
            <b>Mr. {data?.name || "Your Name"}</b> exceeds the current threshold
            or revised threshold notified by Government of India at any future
            date for e-invoicing provisions, then we shall issue invoice and
            credit/ debit note in compliance with the required provisions of GST
            Law.
          </p>
          <br />
          <p>
            In case of any queries from the any state or center Goods and
            Services Tax authorities, <b>Mr. {data?.name || "Your Name"}</b>{" "}
            will be solely responsible for non-compliance of e-invoicing
            provisions laid down under GST law.
          </p>
          <br />
          <p>
            We accordingly confirm that the above statements are true and
            correct and would continue to indemnify Omnicom Media Group India
            Private Limited to whom we have made supplies for any loss caused,
            due to any infirmity caused to them in this regard.
          </p>
          <br />
          <br />
          <p>Yours Truly,</p>
          <b>Mr. {data?.name || "Your Name"}</b>
          {sign ? (
            <img src={sign} alt="Signature" className="signImage my-2 ms-3" />
          ) : (
            <>
              <br />
              <br />
              <br />
            </>
          )}
          <p>Authorized Signatory</p>
          <p>
            Name: <b>{data?.name || "Your Name"}</b>
          </p>
          <p>
            Designation: <b>{data?.designation}</b>
          </p>
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (useruid) {
      if (data?.forms?.einvoicing == 1) {
        await updateDoc(doc(db, "users", useruid), { "forms.einvoicing": 2 });
        showToast("success", "E-Invoicing Form Validated!");
      } else {
        showToast("warning", "Form is Incomplete or Queries are pending!");
      }
    }
  };

  return (
    <>
      <div>
        <ComponentPrint />
        {user?.usertype != "auditor" ? (
          <>
            {data?.forms?.einvoicing === 1 && (
              <button
                onClick={(e) => handleSubmit(e)}
                type="submit"
                className="mt-3 rounded-lg bg-blue-800 text-white w-full p-2 disabled:bg-blue-900"
                disabled={data?.forms?.einvoicing >= 2}
              >
                Validate
              </button>
            )}
            {data?.forms?.einvoicing >= 2 ? (
              <>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button className="rounded-lg bg-blue-800 text-white w-full p-2 my-3">
                        Print
                      </button>
                    );
                  }}
                  content={() => printDivRef.current}
                  documentTitle="Vendor Data Form"
                  pageStyle="print"
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ExportEInvoicing;
