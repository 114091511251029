import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../reusable/navigation/navbar';
import { useDispatch } from 'react-redux';
import { onLogin } from '../../store/actions/loginAction';
import { showToast } from '../../utils/toast';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

function Login() {
    const [show, setShow] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if (data.email == "" || data.password == "") {
            showToast("error", "Please fill the details to login");
            return;
        }
        dispatch(onLogin(navigate, { email: data.email, password: data.password }));
    };

    return (
        <>
            <Navbar />
            <div className="">
                <div className="d-flex justify-content-center glass border-gray-800 align-items-center text-align-center p-4 pb-5" style={{ marginTop: "200px", width: "500px" }}>
                    <div className="col-12 d-flex justify-content-center align-items-center px-4">
                        <div className='col-12'>
                            <h1 className='h3 mb-12 text-center text-white'>Admin Login</h1>
                            <form id='form' className='d-flex flex-column gap-4' onSubmit={handleSubmit(onSubmit)}>
                                <input type="text" className="glass text-white placeholder:text-gray-200 px-3 py-2 rounded-lg" {...register("email")} placeholder="Email" aria-label="Email" />
                                <div className='relative'>
                                    <input
                                        className="glass placeholder:text-gray-400 text-gray-50 px-3 py-2 form-control rounded-lg"
                                        type={show ? "password" : "text"}
                                        {...register("password")}
                                        placeholder="Password"
                                    // autoComplete='off'
                                    />
                                    <div onClick={() => { show ? setShow(false) : setShow(true) }}>
                                        {show ? <AiOutlineEye className='top-3 text-black right-4 absolute h-5 w-5 cursor-pointer' /> :
                                            <AiOutlineEyeInvisible className='top-3 text-black right-4 absolute h-5 w-5 cursor-pointer' />}
                                    </div>
                                </div>
                                <button className='btn btn-primary rounded-lg w-100'>Login</button>
                                {/* <p>Don't have an account? <Link to='/register' className='text-primary'>Sign up</Link></p> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;