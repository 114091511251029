import { arrayUnion, doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../../firebase.config";
import { epochToTimestamp, getStatusIcon, isEmpty } from "../../../utils/utils";
import { BASE_URL, updateDocument } from "../../../utils/api";
import { showToast } from "../../../utils/toast";
import axios from "axios";
import ExportGST from "./exportGST";
import { SmallSpinner } from "../../reusable/spinner/Spinner";
import ReusableModal from "../../reusable/modal/reusablemodal";
import { onAuthStateChanged } from "firebase/auth";

const GSTForm = () => {
  const [uid, setuid] = useState("");
  const [data, setdata] = useState();
  const [docs, setDocs] = useState();
  const [load, setLoad] = useState(false);
  const [queryList, setquerylist] = useState([]);
  const [cnfm, setcnfm] = useState(false);
  const [recall, setrecall] = useState(false);
  const [query, setquery] = useState("");

  const queryTitle = useRef();
  const queryRef = useRef("");
  const navigate = useNavigate();
  const [user, setUser] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        onSnapshot(doc(db, "users", u?.uid), (doc) => {
          var _tmp = doc.data();
          console.log(_tmp);
          setUser(_tmp);
        });
      }
    });
  }, []);

  const useruid = useSelector((state) => {
    return state.getter.uid;
  });

  useEffect(() => {
    if (useruid == "") {
      navigate("/dashboard");
    }
    setuid(useruid);
  }, [useruid]);

  useEffect(() => {
    if (useruid) {
      onSnapshot(doc(db, "users", useruid), (doc) => {
        const _tmp = doc.data();
        setdata(_tmp);
        setquerylist(_tmp?.queries?.nongst);
      });
      onSnapshot(doc(db, "documents", useruid), (doc) => {
        const _tmp = doc.data();
        setDocs(_tmp);
      });
    }
  }, [useruid]);

  const handlQuery = async () => {
    setLoad(true);
    const currentDate = Date.now();
    const list = {
      title: query,
      timestamp: currentDate,
      status: 0,
    };
    await updateDocument("users", useruid, {
      "queries.nongst": arrayUnion(list),
      "forms.nonapplicability": 0,
    });
    if (query === "") {
      showToast("success", "Query updated successfully");
      setLoad(false);
      return;
    }
    setLoad(false);
    queryRef.current.value = "";
    // else {
    //     const payload = {
    //         name: data?.name,
    //         email: data?.email,
    //         query: data?.queries?.nongst,
    //         type: 'Non-Applicability of GST'
    //     }
    //     axios.post(`${BASE_URL}/api/mail`, payload)
    //         .then(response => {
    //             if (response?.data) {
    //                 showToast("success", response?.data?.message);
    //                 setLoad(false);
    //             }
    //         }).catch((err) => {
    //             console.log('Mail not send' + err);
    //             showToast("warning", err);
    //             setLoad(false);
    //         })
    // }
  };

  const handleChangeStatus = async () => {
    queryList &&
      queryList.length > 0 &&
      queryList.map((query, index) => {
        if (query.title == queryTitle.current) {
          queryList[index].status = 2;
          return;
        }
      });
    await updateDocument("users", useruid, { "queries.nongst": queryList });
    showToast("success", "Status changed Successfully!");
    setrecall(false);
  };

  useEffect(() => {
    if (recall && queryList && queryList.length > 0) {
      handleChangeStatus();
    }
  }, [recall === true]);

  return (
    <>
      <div className="mx-5 py-4 h-full rounded-lg border-gray-800 shadow-md flex">
        <div className="w-9/12 m-0 p-10 pt-0 ps-0 align-center rounded-lg border-gray-800">
          {/* Breadcrum */}
          <nav
            className="flex px-4 py-2.5 text-white shadow-none glass"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-3 text-truncate">
              <li className="inline-flex items-center">
                <svg
                  className="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                <Link
                  to="/dashboard"
                  className="inline-flex items-center text-sm font-medium text-gray-100 hover:text-gray-300"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <Link
                    to="/user"
                    className="cursor-pointer ml-1 text-sm font-medium text-gray-100 hover:text-gray-300 md:ml-2 text-truncate"
                  >
                    {data?.name}
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p className="cursor-pointer ml-1 text-sm font-medium hover:text-blue-400 text-blue-400 md:ml-2 text-truncate">
                    Non-applicability of GST
                  </p>
                </div>
              </li>
            </ol>
          </nav>
          {/* End of breadcrum */}
          <ExportGST />
        </div>

        {/* Right Sidebar */}
        <div className="fixed-panel w-3/12 p-10 pt-0 pe-0 align-center rounded-lg border-gray-800">
          <div className="glass px-4 py-2.5 shadow-none text-center text-white">
            <p className="inline-flex items-center space-x-1 md:space-x-3 text-truncate">
              <label className="text-gray-300 h6 mb-0">
                User ID : {isEmpty(data?.userid)}
              </label>
            </p>
          </div>
          {user?.usertype != "auditor" && (
            <div className="glass px-4 py-3 shadow-none my-3 text-white">
              <div className="text-white text-center h5 pb-2 border-bottom border-secondary break-all">
                Non GST Queries
              </div>
              <textarea
                ref={queryRef}
                className="glass w-100 shadow-none"
                cols={10}
                rows={5}
                onChange={(e) => setquery(e.target.value)}
                type="text"
              />
              {load ? (
                <SmallSpinner className="m-auto my-3" loader={load} />
              ) : (
                <button
                  className="rounded-lg bg-blue-800 text-white w-full p-2 mt-2 disabled:bg-blue-900"
                  onClick={handlQuery}
                  disabled={queryRef.current.value == ""}
                >
                  Send Query
                </button>
              )}
            </div>
          )}
          {queryList && queryList.length > 0 ? (
            <div className="glass  px-4 py-3 shadow-none my-3 text-white">
              <div className="text-white text-center h5 pb-2 border-bottom border-secondary break-all">
                Previous Queries
              </div>
              <div className="max-h-56 overflow-auto flex flex-col-reverse">
                {queryList &&
                  queryList.map((q) => (
                    <>
                      <div
                        onClick={() => {
                          if (user?.usertype != "auditor") {
                            queryTitle.current = q.title;
                            setcnfm(true);
                          }
                        }}
                        title={
                          user?.usertype == "auditor"
                            ? q.title
                            : "Click to change status"
                        }
                        className={
                          q.status === 1
                            ? " cursor-pointer text-gray-200 rounded-md mb-2 bg-slate-600 px-3 text-sm py-1 hover:text-gray-100 hover:bg-slate-500"
                            : "text-gray-200 rounded-md mb-2 bg-slate-600 px-3 text-sm py-1 hover:text-gray-100 hover:bg-slate-500"
                        }
                      >
                        <p className="break-all">{q.title}</p>
                        <p className="text-end">
                          <small className="w-full text-xs text-gray-300">
                            {epochToTimestamp(q.timestamp)}
                            <i className={getStatusIcon(q.status)} />
                          </small>
                        </p>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          ) : (
            <div className="glass  px-4 py-3 shadow-none my-3 text-white">
              <div className="text-white text-center break-all">
                No Queries Found
              </div>
            </div>
          )}
        </div>
        {/* end right side */}
      </div>
      {cnfm ? (
        <ReusableModal
          show={cnfm}
          recall={recall}
          setrecall={setrecall}
          onClose={() => setcnfm(false)}
          title={`Query: ${queryTitle?.current}`}
          subtitle="Change the status to Completed?"
          confirmButton="Yes"
          cancelButtom="No"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default GSTForm;
