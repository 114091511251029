import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
} from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase.config";
import { setDocument } from "../../utils/api";
import { generateUniqueID } from "../../utils/utils";
import { loginsuccess, uid } from "../reducers/loginReducer";
import { showToast } from "../../utils/toast";
import { doc, onSnapshot } from "firebase/firestore";

export const onLogin = (navigate, { email, password }) => {
  return async (dispatch) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      onSnapshot(
        doc(db, "users", user?.user?.auth?.currentUser?.uid),
        (doc) => {
          var _tmp = doc.data();
          if (
            _tmp?.usertype === "vendor" ||
            _tmp?.usertype === "trainee" ||
            _tmp?.usertype === "introducer"
          ) {
            signOut(auth)
              .then(() => {
                showToast("error", "User not found");
                sessionStorage.clear();
                navigate("/");
                // window.location.href = "/";
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            dispatch(onGetUsersSuccess(user?.user?.auth?.currentUser));
            dispatch(setUID(user?.user?.auth?.currentUser?.uid));
            sessionStorage.setItem("accessToken", user?.user?.accessToken);
            window.location.href = "/dashboard";
          }
        }
      );
    } catch (err) {
      showToast("warning", err.message);
      console.log(err.message);
    }
  };
};

const onGetUsersSuccess = (data) => {
  return {
    type: loginsuccess,
    payload: data,
  };
};

const setUID = (data) => {
  return {
    type: uid,
    payload: data,
  };
};
