import axios from 'axios';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, updateProfile } from 'firebase/auth';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { auth, db, introauth } from '../../../firebase.config';
import { BASE_URL, setDocument } from '../../../utils/api';
import { showToast } from '../../../utils/toast';
import { generateUniqueID } from '../../../utils/utils';
import { Input } from '../input';
import { Modal, ModalBody, ModalHeader, ModalFooter } from './modal';
import { SmallSpinner } from '../spinner/Spinner';


const ReusableModal = ({ modalTitle, show, onClose, recall, setrecall, title, subtitle, confirmButton, cancelButtom, content }) => {

    return (
        <>
            {show ? (
                <>
                    <Modal>
                        <ModalHeader close={onClose}>
                            <div className='text-white text-2xl'>
                                {modalTitle ? modalTitle : "Note"}
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="block">
                                {title && <p className="text-white text-lg">{title}</p>}
                                {subtitle && <p className="text-gray-400 text-sm mt-3">{subtitle}</p>}
                                <div className="py-2">
                                    {content}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <>
                                <div className='-mt-3'>
                                    {cancelButtom &&
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onClose}
                                        >
                                            {cancelButtom}
                                        </button>
                                    }
                                    {confirmButton &&
                                        <button
                                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            onClick={() => {
                                                setrecall && setrecall(!recall);
                                                onClose();
                                            }}
                                        >
                                            {confirmButton}
                                        </button>
                                    }
                                </div>
                            </>
                        </ModalFooter>
                    </Modal>
                </>
            ) : null}

        </>
    )
}

export default ReusableModal