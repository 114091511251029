import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import PrivateRoutes from "./utils/PrivateRoutes";
import Login from "./components/pages/login";
import Dashboard from "./components/pages/dashboard";
import NavigationBars from "./components/reusable/navigation";
import store from "./store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDetails from "./components/pages/userDetails";
import BankDetails from "./components/forms/vendorBankdetails/bankDetails";
import { useEffect, useState } from "react";
import NDA from "./components/forms/msa/msa";
import VendorForm from "./components/forms/vendorDetails/vendorForm";
import GSTForm from "./components/forms/GST/gstForm";
import EInvoicing from "./components/forms/vendorEInvoicing/EInvoicing";
import TraineeForm from "./components/traineeForms/traineeForm";
import ValidateAuditor from "./components/pages/validateAuditor";

function App() {
  const [token, setToken] = useState();
  // if (token && window.location.pathname == "/" || window.location.pathname == "/register") {
  //   navigate("/dashboard");
  // }

  useEffect(() => {
    const tkn = sessionStorage.getItem("accessToken");
    setToken(tkn);
  }, []);

  return (
    <>
      <Provider store={store}>
        <div
          className={
            token
              ? "App"
              : "d-flex justify-content-center w-100 align-items-center"
          }
          style={{ height: "100%" }}
        >
          <NavigationBars />
          <BrowserRouter>
            <div className="d-flex justify-content-center align-items-center">
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path="/dashboard" exact element={<Dashboard />} />
                  <Route path="/user" exact element={<UserDetails />} />
                  <Route path="/bankdetails" element={<BankDetails />} />
                  <Route path="/msa" element={<NDA />} />
                  <Route path="/vendordata" element={<VendorForm />} />
                  <Route path="/nonapplicability" element={<GSTForm />} />
                  <Route path="/einvoicing" element={<EInvoicing />} />
                  <Route path="/mt-trainee-form" element={<TraineeForm />} />
                </Route>
                <Route path="/validate/:uid" element={<ValidateAuditor />} />
                <Route path="/" exact element={<Login />} />
              </Routes>
            </div>
          </BrowserRouter>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Provider>
    </>
  );
}

export default App;
